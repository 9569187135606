import React, { useMemo } from "react";
import { Grid2 as Grid, Grid2 } from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { ChangePasswordDTO } from "@/api/data-contracts";
import { useModelState } from "@/lib/modelstate";
import { authenticationApi } from "@/boxhub-api";
import * as yup from "yup";
import { Constants } from "@/api/constants";
import { PASSWORD_REGEX } from "@/regex";
import { useTranslation } from "react-i18next";
import { UserFormI18N } from "@/translations";
import { encodePasswordBase64 } from "@/lib/utils";
import { DialogEx } from "@/lib/components/DialogEx";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";

interface ChangePasswordDialogProps {
    open: boolean;
    onClose: () => void;
}

export const useChangePasswordSchema = () => {
    const { t } = useTranslation();
    return useMemo(
        () =>
            yup.object().shape({
                oldPassword: yup
                    .string()
                    .required(t(UserFormI18N.requiredPasswordError))
                    .min(
                        Constants.PASSWORD_LENGTH_MIN,
                        t(UserFormI18N.minPasswordError, { minInteger: Constants.PASSWORD_LENGTH_MIN })
                    )
                    .max(
                        Constants.PASSWORD_LENGTH_MAX,
                        t(UserFormI18N.maxPasswordError, { maxInteger: Constants.PASSWORD_LENGTH_MAX })
                    ),
                newPassword: yup
                    .string()
                    .required(t(UserFormI18N.requiredPasswordError))
                    .min(
                        Constants.PASSWORD_LENGTH_MIN,
                        t(UserFormI18N.minPasswordError, { minInteger: Constants.PASSWORD_LENGTH_MIN })
                    )
                    .max(
                        Constants.PASSWORD_LENGTH_MAX,
                        t(UserFormI18N.maxPasswordError, { maxInteger: Constants.PASSWORD_LENGTH_MAX })
                    )
                    .matches(PASSWORD_REGEX, t(UserFormI18N.invalidPasswordError)),

                newPasswordRepeat: yup
                    .string()
                    .required(t(UserFormI18N.requiredPasswordError))
                    .min(
                        Constants.PASSWORD_LENGTH_MIN,
                        t(UserFormI18N.minPasswordError, { minInteger: Constants.PASSWORD_LENGTH_MIN })
                    )
                    .max(
                        Constants.PASSWORD_LENGTH_MAX,
                        t(UserFormI18N.maxPasswordError, { maxInteger: Constants.PASSWORD_LENGTH_MAX })
                    )
                    .oneOf([yup.ref("newPassword")], t(UserFormI18N.passwordsMustMatchError))
            }) as yup.ObjectSchema<ChangePasswordDTO>,
        [t]
    );
};

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
    open,
    onClose
}: ChangePasswordDialogProps) => {
    const { t } = useTranslation();

    const schema = useChangePasswordSchema();

    const { model, errors, onChange, save } = useModelState<ChangePasswordDTO>({
        initialState: {
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: ""
        },
        sendTransformer: (model: ChangePasswordDTO) => ({
            ...model,
            oldPassword: encodePasswordBase64(model.oldPassword),
            newPassword: encodePasswordBase64(model.newPassword),
            newPasswordRepeat: encodePasswordBase64(model.newPasswordRepeat)
        }),
        saver: authenticationApi.changePassword,
        schema
    });

    const handelChangePassword = () => {
        save({ successMsg: "Passwort erfolgreich geändert" })?.then(() => {
            onClose();
        });
    };

    const buttons = (
        <>
            <ButtonEx
                label="Abbrechen"
                data-cy="cancel-button"
                onClick={onClose}
                variant="outlined"
                color="secondary"
            />
            <ButtonEx
                label="Passwort ändern"
                data-cy="change-password-button"
                onClick={handelChangePassword}
                variant="contained"
            />
        </>
    );

    return (
        <DialogEx
            title="Passwort ändern"
            data-cy="change-password-dialog"
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            buttons={buttons}
        >
            <Grid2 container direction="column" padding={1} spacing={3} width="100%">
                <Grid size={{ sm: 12 }}>
                    <StringInputField
                        name="oldPassword"
                        type="password"
                        autoComplete="new-password"
                        label="Aktuelles Passwort"
                        value={model?.oldPassword}
                        error={errors?.oldPassword}
                        maxChars={Constants.PASSWORD_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 12 }}>
                    <StringInputField
                        name="newPassword"
                        type="password"
                        autoComplete="new-password"
                        label="Neues Passwort"
                        value={model?.newPassword}
                        error={errors?.newPassword}
                        maxChars={Constants.PASSWORD_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 12 }}>
                    <StringInputField
                        name="newPasswordRepeat"
                        type="password"
                        autoComplete="new-password"
                        label={t(UserFormI18N.confirmPasswordLabel)}
                        value={model?.newPasswordRepeat}
                        error={errors?.newPasswordRepeat}
                        maxChars={Constants.PASSWORD_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
            </Grid2>
        </DialogEx>
    );
};
