import { Grid2, Grid2 as Grid, Typography } from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { useModelState } from "@/lib/modelstate";
import * as React from "react";
import { useMemo } from "react";
import * as yup from "yup";
import { ZIP_CODE_REGEX } from "@/regex";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { CompanyFormI18N, CountriesI18N, GeneralI18N } from "@/translations";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import { countryOptions } from "@/datacaches";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import { AddressDTO } from "@/api/data-contracts";
import { addressApi } from "@/boxhub-api";
import { DialogEx } from "@/lib/components/DialogEx";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";

export type AddressEditDialogDialogProps = {
    open: boolean;
    close: () => void;
    onSuccess?: () => void;
    address?: AddressDTO;
};

const useSchema = () => {
    const { t } = useTranslation();
    return useMemo(
        () =>
            yup.object().shape({
                street: yup
                    .string()
                    .optional()
                    .nullable()
                    .transform((value) => (!value ? null : value))
                    .min(
                        Constants.STREET_LENGTH_MIN,
                        t(CompanyFormI18N.minStreetError, { minInteger: Constants.STREET_LENGTH_MIN })
                    )
                    .max(
                        Constants.STREET_LENGTH_MAX,
                        t(CompanyFormI18N.maxStreetError, { maxInteger: Constants.STREET_LENGTH_MAX })
                    ),

                zip: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredPostalCodeError))
                    .min(
                        Constants.ZIP_LENGTH_MIN,
                        t(CompanyFormI18N.minPostalCodeError, { minInteger: Constants.ZIP_LENGTH_MIN })
                    )
                    .max(
                        Constants.ZIP_LENGTH_MAX,
                        t(CompanyFormI18N.maxPostalCodeError, { maxInteger: Constants.ZIP_LENGTH_MAX })
                    )
                    .matches(ZIP_CODE_REGEX, t(CompanyFormI18N.invalidPostalCodeError)),

                city: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCityError))
                    .min(
                        Constants.CITY_LENGTH_MIN,
                        t(CompanyFormI18N.minCityError, { minInteger: Constants.CITY_LENGTH_MIN })
                    )
                    .max(
                        Constants.CITY_LENGTH_MAX,
                        t(CompanyFormI18N.maxCityError, { maxInteger: Constants.CITY_LENGTH_MAX })
                    ),

                country: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCountryError))
                    .oneOf(countryOptions, t(CompanyFormI18N.invalidCountryError)),

                deliveryInstruction: yup.object().shape({
                    mondayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    tuesdayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    wednesdayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    thursdayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    fridayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    saturdayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    sundayInstruction: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.minDeliveryInstructionsError, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    arrivalNotification: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX,
                            t(CompanyFormI18N.preAdviceNoteLabel, {
                                maxInteger: Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX
                            })
                        ),
                    timeSlotBookingRequired: yup.boolean().optional().nullable()
                }),

                companyAddress: yup.boolean()
            }) as yup.ObjectSchema<AddressDTO>,
        [t]
    );
};

export const AddressEditDialog = ({ open, close, onSuccess, address }: AddressEditDialogDialogProps) => {
    const { t } = useTranslation();
    const countrySelectOptions = countryOptions.map((option) => ({
        label: option && t(CountriesI18N[option]),
        value: option
    }));
    const schema = useSchema();
    const { model, errors, onChange, save } = useModelState<AddressDTO>({
        initialState: {
            ...address,
            deliveryInstruction: address?.deliveryInstruction ?? {}
        } as AddressDTO,
        schema,
        updater: addressApi.updateAddress,
        id: address?.id
    });

    const buttons = (
        <>
            <Grid2>
                <ButtonEx label={t(GeneralI18N.cancelButton)} color="secondary" variant="contained" onClick={close} />
            </Grid2>
            <Grid2>
                <ButtonEx
                    label={t(CompanyFormI18N.saveButton)}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        save()?.then(() => {
                            close();
                            onSuccess && onSuccess();
                        })
                    }
                />
            </Grid2>
        </>
    );

    return (
        <DialogEx
            title={t(CompanyFormI18N.addressEditTitle)}
            open={open}
            onClose={close}
            maxWidth="md"
            fullWidth
            buttons={buttons}
        >
            <Grid container spacing={2} padding={2} width="100%">
                <Grid size={{ xs: 12 }}>
                    <StringInputField
                        name="street"
                        value={model?.street}
                        label={t(CompanyFormI18N.streetLabel)}
                        error={errors?.street}
                        maxChars={Constants.STREET_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }}>
                    <StringInputField
                        name="zip"
                        value={model?.zip}
                        label={t(CompanyFormI18N.postalCodeLabel)}
                        error={errors?.zip}
                        maxChars={Constants.ZIP_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }}>
                    <StringInputField
                        name="city"
                        value={model?.city}
                        label={t(CompanyFormI18N.cityLabel)}
                        error={errors?.city}
                        maxChars={Constants.CITY_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }}>
                    <SelectInputField
                        name="country"
                        label={t(CompanyFormI18N.countryLabel)}
                        value={model?.country}
                        error={errors?.country}
                        options={countrySelectOptions}
                        displayEmpty={true}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 6, xs: 12 }}>
                    <CheckboxInputField
                        name="companyAddress"
                        label={t(CompanyFormI18N.companyHeadquarterLabel)}
                        onChange={onChange}
                        disabled={true}
                        value={model?.companyAddress}
                        error={errors?.companyAddress}
                    />
                </Grid>
                <Grid size={{ xs: 12 }} mt={2}>
                    <Typography variant="h6">{t(CompanyFormI18N.deliveryInstructionsTitle)}</Typography>
                </Grid>
                <Grid container spacing={2} size={{ sm: 6, xs: 12 }} direction="column">
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.mondayInstruction"
                            value={model?.deliveryInstruction?.mondayInstruction}
                            label={t(CompanyFormI18N.mondayLabel)}
                            error={errors?.deliveryInstruction?.mondayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.tuesdayInstruction"
                            value={model?.deliveryInstruction?.tuesdayInstruction}
                            label={t(CompanyFormI18N.tuesdayLabel)}
                            error={errors?.deliveryInstruction?.tuesdayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.wednesdayInstruction"
                            value={model?.deliveryInstruction?.wednesdayInstruction}
                            label={t(CompanyFormI18N.wednesdayLabel)}
                            error={errors?.deliveryInstruction?.wednesdayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.thursdayInstruction"
                            value={model?.deliveryInstruction?.thursdayInstruction}
                            label={t(CompanyFormI18N.thursdayLabel)}
                            error={errors?.deliveryInstruction?.thursdayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.fridayInstruction"
                            value={model?.deliveryInstruction?.fridayInstruction}
                            label={t(CompanyFormI18N.fridayLabel)}
                            error={errors?.deliveryInstruction?.fridayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.saturdayInstruction"
                            value={model?.deliveryInstruction?.saturdayInstruction}
                            label={t(CompanyFormI18N.saturdayLabel)}
                            error={errors?.deliveryInstruction?.saturdayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            name="deliveryInstruction.sundayInstruction"
                            value={model?.deliveryInstruction?.sundayInstruction}
                            label={t(CompanyFormI18N.sundayLabel)}
                            error={errors?.deliveryInstruction?.sundayInstruction}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} size={{ sm: 6, xs: 12 }} direction="column">
                    <Grid size={{ xs: 12 }}>
                        <CheckboxInputField
                            name="deliveryInstruction.timeSlotBookingRequired"
                            label={t(CompanyFormI18N.isTimeSlotBookingLabel)}
                            onChange={onChange}
                            value={model?.deliveryInstruction?.timeSlotBookingRequired}
                            error={errors?.deliveryInstruction?.timeSlotBookingRequired}
                        />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <StringInputField
                            multiline={true}
                            name="deliveryInstruction.arrivalNotification"
                            value={model?.deliveryInstruction?.arrivalNotification}
                            label={t(CompanyFormI18N.preAdviceNoteLabel)}
                            error={errors?.deliveryInstruction?.arrivalNotification}
                            maxChars={Constants.DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DialogEx>
    );
};
