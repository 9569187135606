import { brown, orange, yellow } from "@mui/material/colors";
import { useTheme } from "@mui/material";
import { useCallback } from "react";

export const useCardChangeColors = () => {
    const theme = useTheme();
    const darkMode = theme.palette.mode === "dark";

    const getGridChangeSx = useCallback(
        (shall: any, changed: any) => {
            const sxChanged = {
                backgroundColor: darkMode ? brown[900] : orange[100],
                padding: "8px",
                margin: "-6px",
                borderRadius: 2
            };

            const sxChangeable = {
                backgroundColor: darkMode ? brown[600] : yellow[100],
                padding: "8px",
                margin: "-6px",
                borderRadius: 2
            };

            if (shall && changed) {
                return sxChanged;
            } else if (shall) {
                return sxChangeable;
            }
            return undefined;
        },
        [darkMode]
    );

    const getCheckboxChangeSx = useCallback(
        (shall: any, changed: any) => {
            const sxChanged = {
                backgroundColor: darkMode ? brown[900] : orange[100],
                paddingLeft: 1,
                borderRadius: 2
            };

            const sxChangeable = {
                backgroundColor: darkMode ? brown[600] : yellow[100],
                paddingLeft: 1,
                borderRadius: 2
            };

            if (shall && changed) {
                return sxChanged;
            } else if (shall) {
                return sxChangeable;
            }
            return undefined;
        },
        [darkMode]
    );

    return { getGridChangeSx, getCheckboxChangeSx };
};
