import React, {useMemo} from "react";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import {FieldError, nextUID} from "@/lib/utils";

/**
 * Props for the MultiSelectInputField component.
 *
 * @property {string} name - The name of the input field.
 * @property {string} label - The label for the input field.
 * @property {any[]} value - The current value of the input field (array of selected values).
 * @property {FieldError} [error] - The error object for the input field.
 * @property {{ label: string; value: any }[]} options - The options for the select input field.
 * @property {boolean} [disabled] - If true, disables the input field.
 * @property {(event: SelectChangeEvent<any[]>) => void} [onChange] - The handler for change events.
 * @property {React.ReactNode} [endAdornment] - The end adornment for the input field.
 * @property {boolean} [required] - If true, the input field is required.
 */
interface MultiSelectInputFieldProps {
    name: string;
    label: string;
    value: any[];
    error?: FieldError;
    options: { label: string; value: any }[];
    disabled?: boolean;
    onChange?: (event: SelectChangeEvent<any[]>) => void;
    endAdornment?: React.ReactNode;
    required?: boolean;
    renderOption?: (option: { label: string; value: any }) => React.ReactNode;
}

/**
 * MultiSelectInputField component.
 *
 * @param {MultiSelectInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const MultiSelectInputField: React.FC<MultiSelectInputFieldProps> = ({
                                                                         name,
                                                                         label,
                                                                         value,
                                                                         error,
                                                                         options,
                                                                         disabled = false,
                                                                         onChange,
                                                                         endAdornment,
                                                                         required,
                                                                         renderOption
                                                                     }: MultiSelectInputFieldProps): React.ReactElement => {
    const labelId = useMemo(() => `select-label-${nextUID()}`, []);
    const valueTrimmed = options && Array.isArray(value) ? value.filter((v) => options.find((f) => f.value === v)) : [];
    return (
        <FormControl fullWidth size="small" error={!!error} required={required} disabled={disabled}>
            <InputLabel shrink id={labelId}>
                {label}
            </InputLabel>
            <Select
                multiple
                notched
                name={name}
                labelId={labelId}
                label={label}
                value={valueTrimmed}
                disabled={disabled}
                onChange={(e) => {
                    if (!disabled && onChange) {
                        onChange(e);
                    }
                }}
                renderValue={(selected) =>
                    options
                        .filter((option) => selected.includes(option.value))
                        .map((option) => renderOption?.(option) ?? option.label)
                }
                endAdornment={
                    endAdornment ? (
                        <InputAdornment position="end" sx={{position: "absolute", right: 24}}>
                            {endAdornment}
                        </InputAdornment>
                    ) : undefined
                }
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: "70vh",
                            width: "auto",
                            paddingTop: 4,
                            paddingBottom: 4
                        }
                    }
                }}
                sx={{
                    "& .MuiSelect-select": {
                        display: "flex",
                        gap: 1
                    }
                }}
            >
                {options.map((option) => (
                    <MenuItem key={String(option.value)} value={option.value} sx={{padding: 0}}>
                        <Checkbox checked={value.indexOf(option.value) > -1} size="small"/>
                        <ListItemText primary={renderOption?.(option) ?? option.label}/>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText error={!!error}>{error && error.message}</FormHelperText>
        </FormControl>
    );
};

export default MultiSelectInputField;
