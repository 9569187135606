import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid2 } from "@mui/material";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import { Close, Fullscreen } from "@mui/icons-material";

export interface DialogExProps {
    open: boolean;
    onClose: () => void;
    "data-cy"?: string;
    maxWidth?: DialogProps["maxWidth"];
    title: React.ReactNode;
    buttons?: React.ReactNode;
    children: React.ReactNode;
    fullWidth?: boolean;
    allowFullScreen?: boolean;
}

export const DialogEx: React.FC<DialogExProps> = ({
    open,
    onClose,
    "data-cy": dataCy,
    maxWidth,
    title,
    allowFullScreen,
    buttons,
    children,
    fullWidth
}) => {
    const [fullScreen, setFullScreen] = useState(false);
    return (
        <Dialog
            data-cy={dataCy}
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                <Grid2 container direction="row" alignContent="center">
                    <Grid2 flexGrow={1}>{title}</Grid2>
                    {allowFullScreen && (
                        <Grid2>
                            <IconButtonEx Icon={Fullscreen} onClick={() => setFullScreen(!fullScreen)} />
                        </Grid2>
                    )}
                    <Grid2>
                        <IconButtonEx Icon={Close} onClick={() => onClose()} />
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent>
                {/* Wrap children in a form to prevent autocomplete to affect fields in parent view. */}
                <form>{children}</form>
            </DialogContent>
            {buttons && (
                <DialogActions sx={{ display: "flex", justifyContent: "flex-end", spacing: 2, padding: 2 }}>
                    {buttons}
                </DialogActions>
            )}
        </Dialog>
    );
};
