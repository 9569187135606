import * as React from "react";
import { Grid2 as Grid } from "@mui/material";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { useTranslation } from "react-i18next";
import { GeneralI18N, UserFormI18N } from "@/translations";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { DialogEx } from "@/lib/components/DialogEx";
import { onClose } from "@/lib/dialog-controller";
import DateInputField from "@/lib/components/inputfields/date-input-field";
import { ApiTokenDTO } from "@/api/data-contracts";

export interface UserTokenDialogProps {
    open: boolean;
    close: () => void;
    token: ApiTokenDTO;
    handleToken: (apiTokenExpirationDate: string | undefined) => void;
}

export const UserTokenDialog = ({ open, close, token, handleToken }: UserTokenDialogProps) => {
    const { t } = useTranslation();

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <DialogEx
            open={open}
            onClose={close}
            fullWidth
            title={t(UserFormI18N.newApiTokenDialogTitle)}
            buttons={
                <ButtonEx
                    label={t(GeneralI18N.closeButton)}
                    onClick={onClose(close, () => handleToken(token?.expirationDate))}
                    variant="contained"
                    color="primary"
                />
            }
        >
            <Grid container spacing={2} padding={2} width="100%">
                <Grid size={{ xs: 12 }}>
                    <DateInputField
                        name="apiTokenExpirationDate"
                        disabled
                        value={token?.expirationDate}
                        label={t(UserFormI18N.apiTokenExpirationDate)}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <StringInputField
                        name="apiToken"
                        label={t(UserFormI18N.apiTokenLabel)}
                        value={token?.apiToken}
                        disabled
                        endAdornment={
                            <IconButtonEx
                                Icon={ContentCopy}
                                tooltip={t("Copy")}
                                onClick={() => copyToClipboard(token?.apiToken || "")}
                            />
                        }
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <StringInputField
                        name="companyId"
                        label={t(UserFormI18N.companyIdLabel)}
                        value={token?.companyId}
                        disabled
                        endAdornment={
                            <IconButtonEx
                                Icon={ContentCopy}
                                tooltip={t("Copy")}
                                onClick={() => copyToClipboard(token?.companyId || "")}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </DialogEx>
    );
};
