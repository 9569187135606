import * as yup from "yup";
import { CENTRIMETRE_UNIT } from "@/constants";
import { OrderDataDTO, PalletType, QuantityOptionDTO, QuoteDTO } from "@/api/data-contracts";
import { convertDate, formatCurrency, formatInteger } from "@/lib/utils";
import { useMemo } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { palletTypeOptions } from "@/datacaches";
import { Constants } from "@/api/constants";
import { RequestKeyWordsI18N } from "@/translations";
import { OrderDataType } from "@/model/orderData";

export type QuoteValidation = QuoteDTO & {
    validUntil?: Date | null;
    orderData?: Pick<
        OrderDataDTO,
        | "deliveryDate"
        | "glued"
        | "stitched"
        | "taped"
        | "cartonGrade"
        | "palletType"
        | "clicheCosts"
        | "toolingCosts"
        | "palletHeight"
        | "quantityOptions"
        | "paymentTerms"
    > & {
        quantityOptions?: Pick<QuantityOptionDTO, "quantity" | "netPricePer1000">[];
    };
};

export const useQuoteSchema = (mode: OrderDataType = "request") => {
    const { t } = useTranslation();
    const locale = getI18n().language;
    return useMemo(() => {
        const schema = yup
            .object()
            .shape({
                customerReferenceNumber: yup
                    .string()
                    .optional()
                    .nullable()
                    .max(
                        Constants.CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX,
                        t(RequestKeyWordsI18N.maxQuoteNumberLengthError, {
                            maxInteger: formatInteger(Constants.CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX, locale)
                        })
                    ),
                validUntil: yup
                    .date()
                    .optional()
                    .nullable()
                    .transform(convertDate)
                    .typeError(t(RequestKeyWordsI18N.dateFormatError))
                    .required(t(RequestKeyWordsI18N.quoteValidityRequiredError)),
                additionalInformation: yup
                    .string()
                    .nullable()
                    .optional()
                    .max(
                        Constants.ADDITIONAL_INFORMATION_LENGTH_MAX,
                        t(RequestKeyWordsI18N.maxCharactersLengthError, {
                            maxInteger: formatInteger(Constants.ADDITIONAL_INFORMATION_LENGTH_MAX, locale)
                        })
                    ),
                orderData: yup.object().shape({
                    deliveryDate: yup
                        .date()
                        .optional()
                        .nullable()
                        .transform(convertDate)
                        .typeError(t(RequestKeyWordsI18N.dateFormatError)),
                    paymentTerms: yup
                        .string()
                        .nullable()
                        .optional()
                        .max(
                            Constants.PAYMENT_TERMS_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxCharactersLengthError, {
                                maxInteger: formatInteger(Constants.PAYMENT_TERMS_LENGTH_MAX, locale)
                            })
                        ),
                    cartonGrade: yup
                        .string()
                        .optional()
                        .nullable()
                        .max(
                            Constants.CARTON_GRADE_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxVarietyError, {
                                maxInteger: formatInteger(Constants.CARTON_GRADE_LENGTH_MAX, locale)
                            })
                        ),
                    palletType: yup.mixed<PalletType>().optional().nullable().oneOf(palletTypeOptions),
                    glued: yup.boolean().nullable().optional(),
                    stitched: yup.boolean().nullable().optional(),
                    taped: yup.boolean().nullable().optional(),
                    palletizingNotes: yup
                        .string()
                        .max(
                            Constants.PALLETIZING_NOTES_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxPalletizingNotesLengthError, {
                                maxInteger: formatInteger(Constants.PALLETIZING_NOTES_LENGTH_MAX, locale)
                            })
                        )
                        .nullable()
                        .optional(),
                    palletHeight: yup
                        .number()
                        .typeError(t(RequestKeyWordsI18N.integerError))
                        .optional()
                        .nullable()
                        .min(
                            Constants.PALLET_HEIGHT_MIN,
                            t(RequestKeyWordsI18N.minPalletHeightError, {
                                minInteger: formatInteger(Constants.PALLET_HEIGHT_MIN, locale),
                                unit: CENTRIMETRE_UNIT
                            })
                        )
                        .max(
                            Constants.PALLET_HEIGHT_MAX,
                            t(RequestKeyWordsI18N.maxPalletHeightError, {
                                maxInteger: formatInteger(Constants.PALLET_HEIGHT_MAX, locale),
                                unit: CENTRIMETRE_UNIT
                            })
                        )
                        .integer(t(RequestKeyWordsI18N.integerError)),
                    quantityOptions: yup
                        .array(
                            yup
                                .object()
                                .defined()
                                .shape({
                                    quantity: yup
                                        .number()
                                        .typeError(t(RequestKeyWordsI18N.integerError))
                                        .required(t(RequestKeyWordsI18N.requiredQuantityError))
                                        .min(
                                            Constants.QUANTITY_MIN,
                                            t(RequestKeyWordsI18N.minQuantityError, {
                                                minInteger: formatInteger(Constants.QUANTITY_MIN, locale)
                                            })
                                        )
                                        .max(
                                            Constants.QUANTITY_MAX,
                                            t(RequestKeyWordsI18N.maxQuantityError, {
                                                maxInteger: formatInteger(Constants.QUANTITY_MAX, locale)
                                            })
                                        ),
                                    netPricePer1000: yup
                                        .number()
                                        .nullable()
                                        .when([], {
                                            is: () => mode === "quote",
                                            then: (schema) =>
                                                schema.required(t(RequestKeyWordsI18N.requiredNetPriceError))
                                        })
                                        .test(
                                            "min",
                                            t(RequestKeyWordsI18N.minNetPriceError, {
                                                minCurrency: formatCurrency(Constants.NET_PRICE_PER_1000_MIN, locale)
                                            }),
                                            (value) => {
                                                if (value === null || value === undefined) {
                                                    return true;
                                                }
                                                return value >= Constants.NET_PRICE_PER_1000_MIN;
                                            }
                                        )
                                        .test(
                                            "max",
                                            t(RequestKeyWordsI18N.maxNetPriceError, {
                                                maxCurrency: formatCurrency(Constants.NET_PRICE_PER_1000_MAX, locale)
                                            }),
                                            (value) => {
                                                if (value === null || value === undefined) {
                                                    return true;
                                                }
                                                return value <= Constants.NET_PRICE_PER_1000_MAX;
                                            }
                                        )
                                })
                        )
                        .defined()
                        .test("uniqueQuantitiesError", t(RequestKeyWordsI18N.uniqueQuantitiesError), function (value) {
                            if (!value) {
                                return true;
                            }
                            const quantities = value.map((item) => item.quantity).filter((q) => q != null);
                            const unique = new Set(quantities);
                            if (quantities.length !== unique.size) {
                                return this.createError({ path: "quantities" });
                            }
                            return true;
                        }),
                    toolingCosts: yup
                        .number()
                        .optional()
                        .nullable()
                        .when("separateExtraCosts", {
                            is: true,
                            then: (sch) =>
                                sch.when([], {
                                    is: () => mode === "quote",
                                    then: (schema) => schema.required(t(RequestKeyWordsI18N.requiredToolingCostsError)),
                                    otherwise: (schema) => schema
                                })
                        })
                        .test(
                            "min",
                            t(RequestKeyWordsI18N.minToolingCostsError, {
                                minCurrency: formatCurrency(Constants.TOOLING_COSTS_PRICE_MIN, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value >= Constants.TOOLING_COSTS_PRICE_MIN;
                            }
                        )
                        .test(
                            "max",
                            t(RequestKeyWordsI18N.maxToolingCostsError, {
                                maxCurrency: formatCurrency(Constants.TOOLING_COSTS_PRICE_MAX, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value <= Constants.TOOLING_COSTS_PRICE_MAX;
                            }
                        ),

                    clicheCosts: yup
                        .number()
                        .optional()
                        .nullable()
                        .when("separateExtraCosts", {
                            is: true,
                            then: (sch) =>
                                sch.when([], {
                                    is: () => mode === "quote",
                                    then: (schema) => schema.required(t(RequestKeyWordsI18N.requiredClicheCostsError)),
                                    otherwise: (schema) => schema
                                })
                        })
                        .test(
                            "min",
                            t(RequestKeyWordsI18N.minClicheCostsError, {
                                minCurrency: formatCurrency(Constants.CLICHE_COSTS_PRICE_MIN, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value >= Constants.CLICHE_COSTS_PRICE_MIN;
                            }
                        )
                        .test(
                            "max",
                            t(RequestKeyWordsI18N.maxClicheCostsError, {
                                maxCurrency: formatCurrency(Constants.CLICHE_COSTS_PRICE_MAX, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value <= Constants.CLICHE_COSTS_PRICE_MAX;
                            }
                        )
                })
            })
            .stripUnknown();

        const typed = schema as yup.ObjectSchema<QuoteValidation>;
        return typed as yup.ObjectSchema<QuoteDTO>;
    }, [locale, t, mode]);
};
