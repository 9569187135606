import React, { useState } from "react";
import { Grid2 } from "@mui/material";
import { quoteApi } from "@/boxhub-api";
import { QuoteDTO, QuoteStatus, RequestActions, RequestDTO } from "@/api/data-contracts";
import { useNavigate, useParams } from "react-router-dom";
import { AppPaths } from "@/app/AppPaths";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmailIcon from "@mui/icons-material/Email";
import BlockIcon from "@mui/icons-material/Block";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { AppFrame } from "@/app/AppFrame";
import { useSnackbarEx } from "@/lib/snackbarex";
import { useDialogController } from "@/lib/dialog-controller";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import EmailHistoryDialog from "@/common/EmailHistoryDialog";

import { useModelState, ValidatorOptions } from "@/lib/modelstate";
import { quoteReducer } from "@/model/quote";
import { useQuoteSchema } from "@/pages/external-request-page/schema";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { AxiosResponse } from "axios";
import { withAuthWrapperComponent } from "@/app/WithAuthWrapperComponent";
import { OrderDocumentsCard } from "@/common/request-cards/OrderDocumentsCard";
import { QuoteStatusBadge } from "@/common/QuoteStatusBadge";
import { useTranslation } from "react-i18next";
import { GeneralI18N, RequestKeyWordsI18N } from "@/translations";
import { ProductCard } from "@/common/request-cards/ProductCard";
import { PalletizingCard } from "@/common/request-cards/PalletizingCard";
import { PricingCard } from "@/common/request-cards/PricingCard";
import { QuoteCard } from "@/common/request-cards/QuoteCard";
import { RequestCard } from "@/common/request-cards/RequestCard";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { ErrorsShape } from "@/lib/utils";
import { transformToErrorShape } from "@/lib/accessor";
import { ChatGroupCard } from "@/common/chat/ChatGroupCard";

const ExternalRequestPage: React.FC = () => {
    const { t } = useTranslation();
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const schema = useQuoteSchema("quote");
    const { showMessage } = useSnackbarEx();
    const requestId = params.id ? parseInt(params.id, 10) : undefined;
    const [DialogNode, showDialog] = useDialogController();
    const [processing, setProcessing] = useState(false);
    // Added state to control if unsaved changes blocker shall be active
    const [skipUnsavedCheck, setSkipUnsavedCheck] = useState(false);

    // Fetch Quote
    const fetchQuote = async (id: number): Promise<AxiosResponse<QuoteDTO, any>> => {
        if (id == null) {
            throw new Error("Request ID is missing");
        }
        return quoteApi.getOrCreateQuote({ requestId: id });
    };

    const validator = async (model: QuoteDTO, options: ValidatorOptions) => {
        if (options?.action?.type === "--change") {
            const name = options.action?.data[0];
            if (name === "orderData.deliveryDate" || name === "validUntil") {
                const response = await quoteApi.validateQuoteDates({
                    deliveryDate: model.orderData.deliveryDate,
                    validUntilQuote: model.validUntil,
                    validUntilRequest: model.requestDisplay.validUntil
                });
                return transformToErrorShape(response.data?.error?.errors);
            }
        }
        return {} as ErrorsShape<RequestDTO>;
    };

    // Form
    const { model, errors, onChange, status, isDirty, dispatch, validate, setErrors } = useModelState<QuoteDTO>({
        initialState: {} as any,
        reducer: quoteReducer,
        schema,
        id: requestId == null ? null : +requestId,
        loader: fetchQuote,
        validator
    });

    // Springt zurück zum Dashboard
    const handleClosePage = () => {
        navigate(AppPaths.EXTERNAL_REQUEST_OVERVIEW);
    };
    // Withdraw a quote
    const handleWithdrawConfirm = () => {
        // Disable unsaved changes blocker for this intentional navigation
        setSkipUnsavedCheck(true);
        if (model.id) {
            quoteApi
                .withdrawQuote(model.id)
                .then(() => {
                    showMessage({
                        summary: t(RequestKeyWordsI18N.successQuoteWithdrawalMessage),
                        severity: "success"
                    });
                })
                .catch((error) => {
                    showMessage({ summary: t(GeneralI18N.unknownError), severity: "error", error });
                })
                .finally(() => {
                    navigate(AppPaths.EXTERNAL_REQUEST_OVERVIEW);
                });
        }
    };

    // Send a Quote
    const handleQuoteSubmit = async () => {
        if (validate()) {
            await doSave();
        }
    };

    const doSave = async () => {
        // Disable unsaved changes blocker for this intentional navigation
        setSkipUnsavedCheck(true);
        if (model != null) {
            setProcessing(true);
            quoteApi
                .submitQuote(model)
                .then((quoteResult) => {
                    const newQuote = quoteResult.data;
                    dispatch({ type: "--set", data: newQuote });
                    showMessage({
                        summary: t(RequestKeyWordsI18N.quoteSubmittedSuccessMessage, {
                            boxhubNumber: model.requestDisplay?.boxhubNumber ?? t(GeneralI18N.notGiven)
                        }),
                        severity: "success"
                    });
                })
                .catch((error) => {
                    showMessage({ summary: t(GeneralI18N.unknownError), severity: "error", error });
                    setErrors(error);
                })
                .finally(() => {
                    navigate(AppPaths.EXTERNAL_REQUEST_OVERVIEW);
                    setProcessing(false);
                });
        }
    };

    // Dialog Handlers
    const handleSubmitQuote = () => {
        if (validate()) {
            showDialog((open, close) => (
                <ConfirmationDialog
                    open={open}
                    onClose={close}
                    onConfirm={handleQuoteSubmit}
                    title={t(RequestKeyWordsI18N.sendQuoteButton)}
                    message={t(RequestKeyWordsI18N.questionSubmitQuoteMessage)}
                    confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                    cancelButtonText={t(GeneralI18N.cancelButton)}
                />
            ));
        }
    };

    const handleRevokeRequest = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={() => {
                    setProcessing(true);
                    quoteApi
                        .revokeRejectedRequestAsOfferor(model.requestDisplay.id!)
                        .then(() => {
                            showMessage({
                                summary: t(RequestKeyWordsI18N.requestRevokeSuccessMessage, {
                                    boxhubNumber: model.requestDisplay?.boxhubNumber
                                }),
                                severity: "success"
                            });
                            navigate(AppPaths.EXTERNAL_REQUEST_OVERVIEW);
                        })
                        .catch((error) => {
                            showMessage({ summary: t(GeneralI18N.unknownError), severity: "error", error });
                        })
                        .finally(() => {
                            setProcessing(false);
                        });
                }}
                title={t(RequestKeyWordsI18N.revokeRequestButton)}
                message={t(RequestKeyWordsI18N.questionRevokeRequestMessage)}
                confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                cancelButtonText={t(GeneralI18N.cancelButton)}
            />
        ));
    };

    const handleRejectRequest = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={() => {
                    setProcessing(true);
                    quoteApi
                        .rejectRequest({
                            requestId: model.requestDisplay.id!
                        })
                        .then(() => {
                            showMessage({
                                summary: t(RequestKeyWordsI18N.requestRejectedSuccessMessage, {
                                    boxhubNumber: model.requestDisplay?.boxhubNumber
                                }),
                                severity: "success"
                            });
                            navigate(AppPaths.EXTERNAL_REQUEST_OVERVIEW);
                        })
                        .catch((error) => {
                            showMessage({ summary: t(GeneralI18N.unknownError), severity: "error", error });
                        })
                        .finally(() => {
                            setProcessing(false);
                        });
                }}
                title={t(RequestKeyWordsI18N.rejectRequestButton)}
                message={t(RequestKeyWordsI18N.questionRejectRequestMessage)}
                confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                cancelButtonText={t(GeneralI18N.cancelButton)}
            />
        ));
    };

    const handleWithdrawClick = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={handleWithdrawConfirm}
                title={t(RequestKeyWordsI18N.confirmQuoteWithdrawalTitle)}
                message={t(RequestKeyWordsI18N.questionQuoteWithdrawalMessage)}
                confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                cancelButtonText={t(GeneralI18N.cancelButton)}
            />
        ));
    };

    const handleOpenHistoryDialog = () => {
        if (requestId) {
            showDialog((open, close) => (
                <EmailHistoryDialog open={open} requestId={requestId} onClose={close} enableSendStatus={false} />
            ));
        }
    };

    const buttons = (
        <Grid2 container direction="row" spacing={2}>
            <ButtonEx
                variant={"contained"}
                startIcon={<ArrowBackIosIcon />}
                onClick={handleClosePage}
                label={t(RequestKeyWordsI18N.backtoOverviewButton)}
            />
            <ButtonEx
                visible={!!requestId}
                variant="contained"
                startIcon={<EmailIcon />}
                onClick={handleOpenHistoryDialog}
                label={t(RequestKeyWordsI18N.emailHistoryButton)}
            />
            {model.requestDisplay?.requestActions?.includes(RequestActions.WITHDRAW_QUOTE) && (
                <ButtonEx
                    data-cy="withdraw-button"
                    label={t(RequestKeyWordsI18N.withdrawQuoteButton)}
                    variant="contained"
                    color="primary"
                    startIcon={<BlockIcon />}
                    onClick={handleWithdrawClick}
                />
            )}
            {model.requestDisplay?.requestActions?.includes(RequestActions.BID_FOR_REQUEST) && (
                <ButtonEx
                    data-cy="send-quote-button"
                    label={t(RequestKeyWordsI18N.sendQuoteButton)}
                    variant="contained"
                    color="success"
                    startIcon={<ThumbUp />}
                    disabled={processing}
                    onClick={handleSubmitQuote}
                />
            )}
            {model.requestDisplay?.requestActions?.includes(RequestActions.REJECT_REQUEST) && (
                <ButtonEx
                    data-cy="reject-request-button"
                    label={t(RequestKeyWordsI18N.rejectRequestButton)}
                    variant="contained"
                    color="error"
                    startIcon={<ThumbDown />}
                    disabled={processing}
                    onClick={handleRejectRequest}
                />
            )}
            {model.requestDisplay?.requestActions?.includes(RequestActions.REVOKE_REJECTED_QUOTE) && (
                <ButtonEx
                    data-cy={"revoke-request-button"}
                    label={t(RequestKeyWordsI18N.revokeRequestButton)}
                    variant="contained"
                    color="success"
                    startIcon={<AutorenewIcon />}
                    disabled={processing}
                    onClick={handleRevokeRequest}
                />
            )}
        </Grid2>
    );

    const readonly = model?.status !== QuoteStatus.VACANT;
    const orderDataOrg = model?.requestDisplay?.orderData;

    return (
        <AppFrame
            loading={status === "loading"}
            // Only show unsaved changes blocker when skipUnsavedCheck is false
            isDirty={!skipUnsavedCheck && isDirty}
            title={
                <Grid2 container spacing={3} direction="row" alignItems="center">
                    {t(RequestKeyWordsI18N.externalRequestDetailsTitle, {
                        boxhub: model.requestDisplay?.boxhubNumber ?? t(GeneralI18N.notGiven)
                    })}
                    <QuoteStatusBadge status={model?.status} />
                </Grid2>
            }
            buttons={buttons}
        >
            <Grid2 container spacing={2} size={{ xs: 12 }} padding={2}>
                <Grid2 container direction="column" spacing={2} size={{ sm: 12, md: 4 }} alignContent="flex-start">
                    <Grid2 size={{ xs: 12 }}>
                        <RequestCard model={model.requestDisplay} mode="quote" />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <OrderDocumentsCard orderDocuments={model.orderDocuments} readonly />
                    </Grid2>
                </Grid2>
                <Grid2 container direction="column" spacing={2} size={{ sm: 12, md: 4 }} alignContent="flex-start">
                    <Grid2 size={{ xs: 12 }}>
                        <ProductCard
                            orderData={model.orderData}
                            orgData={orderDataOrg}
                            onChange={onChange}
                            errors={errors}
                            mode="quote"
                            readonly={readonly}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <PalletizingCard
                            orderData={model.orderData}
                            orgData={orderDataOrg}
                            onChange={onChange}
                            errors={errors}
                            mode="quote"
                            readonly={readonly}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container direction="column" spacing={2} size={{ sm: 12, md: 4 }} alignContent="flex-start">
                    <Grid2 size={{ xs: 12 }}>
                        <PricingCard
                            orderData={model.orderData}
                            orgData={orderDataOrg}
                            onChange={onChange}
                            errors={errors}
                            dispatch={dispatch}
                            mode="quote"
                            readonly={readonly}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <QuoteCard model={model} errors={errors} onChange={onChange} readonly={readonly} mode="quote" />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <ChatGroupCard
                            chatGroup={model?.chatGroup}
                            dispatch={dispatch}
                            canAsk
                            readonly={!model.requestDisplay?.requestActions?.includes(RequestActions.ASK_IN_CHAT)}
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <DialogNode />
        </AppFrame>
    );
};

export default withAuthWrapperComponent(ExternalRequestPage);
