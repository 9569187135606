import { Close } from "@mui/icons-material";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { IconButtonEx } from "./components/buttons/IconButtonEx";
import { Grid2 } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { MessagesI18N } from "@/translations";

export type ShowSnackbarProps = {
    severity: "warning" | "error" | "info" | "success";
    life?: number;
    sticky?: boolean;
    summary: React.ReactNode;
    details?: React.ReactNode;
    list?: string[];
    error?: any;
    snackbarKey?: SnackbarKey;
};

export const useSnackbarEx = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showMessage = useCallback(
        ({ severity, life = 9000, sticky, summary, details, list, error, snackbarKey }: ShowSnackbarProps) => {
            const closeHandler = (snackKey: SnackbarKey) => (
                <IconButtonEx
                    size="small"
                    data-cy="snackbar-close-button"
                    Icon={Close}
                    onClick={() => closeSnackbar(snackKey)}
                />
            );
            if (error?.status === 401) {
                summary = t(MessagesI18N.sessionExpiredSummary);
                details = t(MessagesI18N.sessionExpiredDetails);
            } else if (error?.status === 403) {
                summary = t(MessagesI18N.accessDeniedSummary);
                details = t(MessagesI18N.accessDeniedDetails);
            } else if (error?.status === 413) {
                summary = t(MessagesI18N.contentTooLargeSummary);
                details = t(MessagesI18N.contentTooLargeDetails);
            } else if (error?.response?.data?.error) {
                summary = error.response.data.error.message;
            }
            if (snackbarKey) {
                closeSnackbar(snackbarKey);
            }
            return enqueueSnackbar(
                <Grid2 container direction="column">
                    <Grid2>{summary}</Grid2>
                    {details && <Grid2>{details}</Grid2>}
                    {list && (
                        <Grid2>
                            <ul style={{ marginTop: 0, marginBottom: 0, paddingLeft: 24 }}>
                                {list.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </Grid2>
                    )}
                    {error?.message && (
                        <Collapsed>
                            <Grid2 sx={{ "&>pre": { marginTop: 1, marginBottom: 0, fontSize: "80%" } }}>
                                <pre>{error.message}</pre>
                            </Grid2>
                        </Collapsed>
                    )}
                </Grid2>,
                {
                    variant: severity,
                    autoHideDuration: sticky ? null : life,
                    action: sticky || life > 8000 ? closeHandler : null
                    // preventDuplicate: true,
                }
            );
        },
        [closeSnackbar, enqueueSnackbar, t]
    );

    return { showMessage, enqueueSnackbar, closeSnackbar };
};

const Collapsed = ({ children }: { children: React.ReactNode }) => {
    const [expanded, setExpanded] = React.useState(false);
    return expanded ? children : <ButtonEx label="details" size="small" onClick={() => setExpanded(!expanded)} />;
};
