import { AxiosError, AxiosProgressEvent } from "axios";
import { useSnackbarEx } from "@/lib/snackbarex";
import { useCallback, useRef, useState } from "react";
import { FileUploadInfo, FileUploadInfoApi } from "@/lib/components/FileUploadInfo";
import { RequestParams } from "@/api/http-client";
import { useTranslation } from "react-i18next";
import { RequestKeyWordsI18N } from "@/translations";

export type FileUploadHandler = [(files: File[]) => void, boolean];

export type UploadFunction<T> = (files: File[], params: RequestParams) => Promise<T>;

export const useFileUploadHandler = <T = any,>(
    uploadFunction: UploadFunction<T>,
    onError?: (error: AxiosError) => boolean
): FileUploadHandler => {
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar, showMessage } = useSnackbarEx();

    const progressRef = useRef<FileUploadInfoApi>(null);

    const [uploading, setUploading] = useState(false);

    const onUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
        if (progressRef.current) {
            progressRef.current.update(progressEvent);
        }
    }, []);

    const upload = useCallback(
        (files: File[]) => {
            const snackKey = enqueueSnackbar(<FileUploadInfo ref={progressRef} files={files} />, {
                persist: true,
                variant: "info"
            });

            setUploading(true);

            uploadFunction(files, { onUploadProgress })
                .then(() => {
                    closeSnackbar(snackKey);
                })
                .catch((error) => {
                    closeSnackbar(snackKey);
                    if (onError && !onError(error)) {
                        showMessage({ summary: t(RequestKeyWordsI18N.uploadFilesError), severity: "error", error });
                    }
                })
                .finally(() => {
                    setUploading(false);
                });
        },
        [closeSnackbar, enqueueSnackbar, onUploadProgress, showMessage, uploadFunction]
    );

    return [upload, uploading];
};
