export const HomePageI18N = {
  description: "HomePage.description",
  product: "HomePage.product",
  ourlatest: "HomePage.ourlatest"
};

export const CompanyStatusI18N = {
  OPERATOR: "CompanyStatus.OPERATOR",
  INVITED: "CompanyStatus.INVITED",
  UNVERIFIED: "CompanyStatus.UNVERIFIED",
  CONFIRMED: "CompanyStatus.CONFIRMED",
  TERMINATION_RECEIVED: "CompanyStatus.TERMINATION_RECEIVED",
  TERMINATION_RECEIVED_LOCKED: "CompanyStatus.TERMINATION_RECEIVED_LOCKED",
  TERMINATED: "CompanyStatus.TERMINATED",
  REJECTED: "CompanyStatus.REJECTED",
  DEACTIVATED: "CompanyStatus.DEACTIVATED",
  CONFIRMED_LOCKED: "CompanyStatus.CONFIRMED_LOCKED"
};

export const AdministrationI18N = {
  companyRequestsTitle: "Administration.companyRequestsTitle",
  companyOverviewTitle: "Administration.companyOverviewTitle",
  companyUsersOverviewTitle: "Administration.companyUsersOverviewTitle",
  userActiveLabel: "Administration.userActiveLabel",
  userInactiveLabel: "Administration.userInactiveLabel",
  companyNameLabel: "Administration.companyNameLabel",
  streetLabel: "Administration.streetLabel",
  postalCodeLabel: "Administration.postalCodeLabel",
  countryLabel: "Administration.countryLabel",
  numberOfActiveCompanyUsersLabel: "Administration.numberOfActiveCompanyUsersLabel",
  lastUpdatedLabel: "Administration.lastUpdatedLabel",
  companyStatusLabel: "Administration.companyStatusLabel",
  isCEOLabel: "Administration.isCEOLabel",
  isCEOShortLabel: "Administration.isCEOShortLabel",
  userStatusLabel: "Administration.userStatusLabel",
  inviteCompanyButton: "Administration.inviteCompanyButton",
  searchButton: "Administration.searchButton",
  noCompaniesFoundLabel: "Administration.noCompaniesFoundLabel",
  requestsLabel: "Administration.requestsLabel",
  quotesLabel: "Administration.quotesLabel",
  companyLabel: "Administration.companyLabel",
  unlockForEditingDescription: "Administration.unlockForEditingDescription",
  lockEditingDescription: "Administration.lockEditingDescription",
  signUpRequestApproveButton: "Administration.signUpRequestApproveButton",
  signUpRequestRejectButton: "Administration.signUpRequestRejectButton",
  searchFailedError: "Administration.searchFailedError",
  approvingRequestSuccessMessage: "Administration.approvingRequestSuccessMessage",
  approvingRequestFailedError: "Administration.approvingRequestFailedError",
  rejectingRequestSuccessMessage: "Administration.rejectingRequestSuccessMessage",
  rejectingRequestFailedError: "Administration.rejectingRequestFailedError",
  saveCompanySuccessMessage: "Administration.saveCompanySuccessMessage",
  invitationSuccessMessage: "Administration.invitationSuccessMessage",
  invitationFailedError: "Administration.invitationFailedError",
  requestButton: "Administration.requestButton",
  saveChangesButton: "Administration.saveChangesButton",
  noEmployeesFoundLabel: "Administration.noEmployeesFoundLabel",
  missingCompanyIdError: "Administration.missingCompanyIdError",
  dismissQuoteTitle: "Administration.dismissQuoteTitle",
  questionDismissQuoteMessage: "Administration.questionDismissQuoteMessage"
};

export const UserSignUpPageI18N = {
  pageTitle: "UserSignUpPage.pageTitle",
  PasswordForm: {
    cardTitle: "UserSignUpPage.PasswordForm.cardTitle",
    submitButton: "UserSignUpPage.PasswordForm.submitButton"
  },
  FinishedPage: {
    failedTitle: "UserSignUpPage.FinishedPage.failedTitle",
    failedSubtitle: "UserSignUpPage.FinishedPage.failedSubtitle"
  },
  Snackbar: {
    successMessage: "UserSignUpPage.Snackbar.successMessage",
    errorMessage: "UserSignUpPage.Snackbar.errorMessage"
  }
};

export const CountriesI18N = {
  GERMANY: "Countries.GERMANY",
  NETHERLANDS: "Countries.NETHERLANDS"
};

export const SalutationsI18N = {
  MR: "Salutations.MR",
  MS: "Salutations.MS",
  DR_MALE: "Salutations.DR_MALE",
  DR_FEMALE: "Salutations.DR_FEMALE",
  PROF_MALE: "Salutations.PROF_MALE",
  PROF_FEMALE: "Salutations.PROF_FEMALE"
};

export const LanguagesI18N = {
  GERMAN: "Languages.GERMAN"
};

export const UserStatusI18N = {
  INVITED: "UserStatus.INVITED",
  UNCONFIRMED: "UserStatus.UNCONFIRMED",
  EMAIL_CONFIRMED: "UserStatus.EMAIL_CONFIRMED",
  CONFIRMED: "UserStatus.CONFIRMED",
  DEACTIVATED: "UserStatus.DEACTIVATED"
};

export const TerminationI18N = {
  companyTerminationDialogTitle: "Termination.companyTerminationDialogTitle",
  terminationCardTitle: "Termination.terminationCardTitle",
  terminationStatusLabel: "Termination.terminationStatusLabel",
  terminationDateLabel: "Termination.terminationDateLabel",
  terminationReasonLabel: "Termination.terminationReasonLabel",
  terminationInitiatorLabel: "Termination.terminationInitiatorLabel",
  terminationRequestAtLabel: "Termination.terminationRequestAtLabel",
  terminationRequestByLabel: "Termination.terminationRequestByLabel",
  terminateCompanyButton: "Termination.terminateCompanyButton",
  companyTerminationConfirmationDialogTitle: "Termination.companyTerminationConfirmationDialogTitle",
  companyTerminationConfirmationDialogMessage: "Termination.companyTerminationConfirmationDialogMessage",
  requiredTerminationDateError: "Termination.requiredTerminationDateError",
  invalidTerminationDateError: "Termination.invalidTerminationDateError",
  requiredTerminationInitiatorError: "Termination.requiredTerminationInitiatorError",
  invalidTerminationInitiatorError: "Termination.invalidTerminationInitiatorError",
  requiredTerminationReasonError: "Termination.requiredTerminationReasonError",
  minTerminationReasonError: "Termination.minTerminationReasonError",
  maxTerminationReasonError: "Termination.maxTerminationReasonError",
  revertTerminationButton: "Termination.revertTerminationButton",
  terminationRevertFailedError: "Termination.terminationRevertFailedError",
  terminationRevertSuccessMessage: "Termination.terminationRevertSuccessMessage",
  terminationRevertConfirmationDialogTitle: "Termination.terminationRevertConfirmationDialogTitle",
  terminationRevertConfirmationDialogMessage: "Termination.terminationRevertConfirmationDialogMessage"
};

export const BlockingI18N = {
  blockCardTitle: "Blocking.blockCardTitle",
  blockStatusLabel: "Blocking.blockStatusLabel",
  blockReasonLabel: "Blocking.blockReasonLabel",
  lockRequestAtLabel: "Blocking.lockRequestAtLabel",
  lockRequestByLabel: "Blocking.lockRequestByLabel",
  lockedLabel: "Blocking.lockedLabel",
  blockCompanyDialogTitle: "Blocking.blockCompanyDialogTitle",
  blockCompanyConfirmDialogTitle: "Blocking.blockCompanyConfirmDialogTitle",
  blockCompanyConfirmDialogMessage: "Blocking.blockCompanyConfirmDialogMessage",
  requiredBlockingReasonError: "Blocking.requiredBlockingReasonError",
  minBlockingReasonError: "Blocking.minBlockingReasonError",
  maxBlockingReasonError: "Blocking.maxBlockingReasonError",
  blockCompanyButton: "Blocking.blockCompanyButton",
  unblockCompanyButton: "Blocking.unblockCompanyButton",
  blockingRevertFailedError: "Blocking.blockingRevertFailedError",
  blockingRevertSuccessMessage: "Blocking.blockingRevertSuccessMessage",
  blockingRevertConfirmationDialogTitle: "Blocking.blockingRevertConfirmationDialogTitle",
  blockingRevertConfirmationDialogMessage: "Blocking.blockingRevertConfirmationDialogMessage",
  sumOpenRequestsLabel: "Blocking.sumOpenRequestsLabel",
  sumSentQuotesLabel: "Blocking.sumSentQuotesLabel"
};

export const ReactivationI18N = {
  reactivateCompanyButton: "Reactivation.reactivateCompanyButton",
  reactivateCompanySuccessMessage: "Reactivation.reactivateCompanySuccessMessage",
  reactivateCompanyFailedError: "Reactivation.reactivateCompanyFailedError",
  reactivateCompanyConfirmDialogTitle: "Reactivation.reactivateCompanyConfirmDialogTitle",
  reactivateCompanyConfirmDialogMessage: "Reactivation.reactivateCompanyConfirmDialogMessage"
};

export const TerminationInitiatorI18N = {
  CLIENT: "TerminationInitiator.CLIENT",
  OPERATOR: "TerminationInitiator.OPERATOR"
};

export const CompanyFormI18N = {
  addressEditTitle: "CompanyForm.addressEditTitle",
  companyFormTitle: "CompanyForm.companyFormTitle",
  moreDataTitle: "CompanyForm.moreDataTitle",
  moreDataCompanyTitle: "CompanyForm.moreDataCompanyTitle",
  erpDataTitle: "CompanyForm.erpDataTitle",
  bankConnectionTitle: "CompanyForm.bankConnectionTitle",
  erpSystemTitle: "CompanyForm.erpSystemTitle",
  inviteCompanyTitle: "CompanyForm.inviteCompanyTitle",
  deliveryInstructionsTitle: "CompanyForm.deliveryInstructionsTitle",
  companyNameLabel: "CompanyForm.companyNameLabel",
  minCompanyNameError: "CompanyForm.minCompanyNameError",
  maxCompanyNameError: "CompanyForm.maxCompanyNameError",
  countryLabel: "CompanyForm.countryLabel",
  legalFormLabel: "CompanyForm.legalFormLabel",
  companyWebsiteLabel: "CompanyForm.companyWebsiteLabel",
  commercialRegisterNumberLabel: "CompanyForm.commercialRegisterNumberLabel",
  vatIdLabel: "CompanyForm.vatIdLabel",
  streetLabel: "CompanyForm.streetLabel",
  postalCodeLabel: "CompanyForm.postalCodeLabel",
  cityLabel: "CompanyForm.cityLabel",
  isCompanyHeadquarterLabel: "CompanyForm.isCompanyHeadquarterLabel",
  companyHeadquarterLabel: "CompanyForm.companyHeadquarterLabel",
  isTimeSlotBookingLabel: "CompanyForm.isTimeSlotBookingLabel",
  timeSlotBookingLabel: "CompanyForm.timeSlotBookingLabel",
  deliveryInstructionsLabel: "CompanyForm.deliveryInstructionsLabel",
  preAdviceNoteLabel: "CompanyForm.preAdviceNoteLabel",
  officeHoursLabel: "CompanyForm.officeHoursLabel",
  deliveryHoursLabel: "CompanyForm.deliveryHoursLabel",
  erpManufacturerLabel: "CompanyForm.erpManufacturerLabel",
  erpSoftwareNameLabel: "CompanyForm.erpSoftwareNameLabel",
  erpSoftwareVersionLabel: "CompanyForm.erpSoftwareVersionLabel",
  machineryParkLabel: "CompanyForm.machineryParkLabel",
  additionalInformationLabel: "CompanyForm.additionalInformationLabel",
  bankNameLabel: "CompanyForm.bankNameLabel",
  ibanLabel: "CompanyForm.ibanLabel",
  bicLabel: "CompanyForm.bicLabel",
  paymentTermsLabel: "CompanyForm.paymentTermsLabel",
  mondayLabel: "CompanyForm.mondayLabel",
  tuesdayLabel: "CompanyForm.tuesdayLabel",
  wednesdayLabel: "CompanyForm.wednesdayLabel",
  thursdayLabel: "CompanyForm.thursdayLabel",
  fridayLabel: "CompanyForm.fridayLabel",
  saturdayLabel: "CompanyForm.saturdayLabel",
  sundayLabel: "CompanyForm.sundayLabel",
  mondayShortLabel: "CompanyForm.mondayShortLabel",
  tuesdayShortLabel: "CompanyForm.tuesdayShortLabel",
  wednesdayShortLabel: "CompanyForm.wednesdayShortLabel",
  thursdayShortLabel: "CompanyForm.thursdayShortLabel",
  fridayShortLabel: "CompanyForm.fridayShortLabel",
  saturdayShortLabel: "CompanyForm.saturdayShortLabel",
  sundayShortLabel: "CompanyForm.sundayShortLabel",
  requiredCompanyNameError: "CompanyForm.requiredCompanyNameError",
  requiredCountryError: "CompanyForm.requiredCountryError",
  invalidCountryError: "CompanyForm.invalidCountryError",
  requiredLegalFormError: "CompanyForm.requiredLegalFormError",
  invalidLegalFormError: "CompanyForm.invalidLegalFormError",
  requiredCompanyWebsiteError: "CompanyForm.requiredCompanyWebsiteError",
  minCompanyWebsiteError: "CompanyForm.minCompanyWebsiteError",
  maxCompanyWebsiteError: "CompanyForm.maxCompanyWebsiteError",
  invalidCompanyWebsiteError: "CompanyForm.invalidCompanyWebsiteError",
  requiredCommercialRegistrationNumberError: "CompanyForm.requiredCommercialRegistrationNumberError",
  minCommercialRegistrationNumberError: "CompanyForm.minCommercialRegistrationNumberError",
  maxCommercialRegistrationNumberError: "CompanyForm.maxCommercialRegistrationNumberError",
  invalidCommercialRegistrationNumberError: "CompanyForm.invalidCommercialRegistrationNumberError",
  requiredVatIdError: "CompanyForm.requiredVatIdError",
  minVatIdError: "CompanyForm.minVatIdError",
  maxVatIdError: "CompanyForm.maxVatIdError",
  invalidVatIdError: "CompanyForm.invalidVatIdError",
  minStreetError: "CompanyForm.minStreetError",
  maxStreetError: "CompanyForm.maxStreetError",
  requiredPostalCodeError: "CompanyForm.requiredPostalCodeError",
  minPostalCodeError: "CompanyForm.minPostalCodeError",
  maxPostalCodeError: "CompanyForm.maxPostalCodeError",
  invalidPostalCodeError: "CompanyForm.invalidPostalCodeError",
  requiredCityError: "CompanyForm.requiredCityError",
  minCityError: "CompanyForm.minCityError",
  maxCityError: "CompanyForm.maxCityError",
  requiredOfficeHoursError: "CompanyForm.requiredOfficeHoursError",
  minOfficeHoursError: "CompanyForm.minOfficeHoursError",
  maxOfficeHoursError: "CompanyForm.maxOfficeHoursError",
  requiredDeliveryHoursError: "CompanyForm.requiredDeliveryHoursError",
  minDeliveryHoursError: "CompanyForm.minDeliveryHoursError",
  maxDeliveryHoursError: "CompanyForm.maxDeliveryHoursError",
  requiredErpManufacturerError: "CompanyForm.requiredErpManufacturerError",
  minErpManufacturerError: "CompanyForm.minErpManufacturerError",
  maxErpManufacturerError: "CompanyForm.maxErpManufacturerError",
  requiredErpSoftwareNameError: "CompanyForm.requiredErpSoftwareNameError",
  minErpSoftwareNameError: "CompanyForm.minErpSoftwareNameError",
  maxErpSoftwareNameError: "CompanyForm.maxErpSoftwareNameError",
  requiredErpSoftwareVersionError: "CompanyForm.requiredErpSoftwareVersionError",
  minErpSoftwareVersionError: "CompanyForm.minErpSoftwareVersionError",
  maxErpSoftwareVersionError: "CompanyForm.maxErpSoftwareVersionError",
  requiredMachineryParkError: "CompanyForm.requiredMachineryParkError",
  minMachineryParkError: "CompanyForm.minMachineryParkError",
  maxMachineryParkError: "CompanyForm.maxMachineryParkError",
  requiredAdditionalInformationError: "CompanyForm.requiredAdditionalInformationError",
  minAdditionalInformationError: "CompanyForm.minAdditionalInformationError",
  maxAdditionalInformationError: "CompanyForm.maxAdditionalInformationError",
  requiredBankNameError: "CompanyForm.requiredBankNameError",
  minBankNameError: "CompanyForm.minBankNameError",
  maxBankNameError: "CompanyForm.maxBankNameError",
  requiredIbanError: "CompanyForm.requiredIbanError",
  minIbanError: "CompanyForm.minIbanError",
  maxIbanError: "CompanyForm.maxIbanError",
  invalidIbanError: "CompanyForm.invalidIbanError",
  requiredBicError: "CompanyForm.requiredBicError",
  minBicError: "CompanyForm.minBicError",
  maxBicError: "CompanyForm.maxBicError",
  invalidBicError: "CompanyForm.invalidBicError",
  requiredPaymentTermsError: "CompanyForm.requiredPaymentTermsError",
  minPaymentTermsError: "CompanyForm.minPaymentTermsError",
  maxPaymentTermsError: "CompanyForm.maxPaymentTermsError",
  minDeliveryInstructionsError: "CompanyForm.minDeliveryInstructionsError",
  saveButton: "CompanyForm.saveButton",
  lockFormDialogTitle: "CompanyForm.lockFormDialogTitle",
  lockFormDialogMessage: "CompanyForm.lockFormDialogMessage",
  loadStatisticsFailedError: "CompanyForm.loadStatisticsFailedError"
};

export const UserFormI18N = {
  userFormTitle: "UserForm.userFormTitle",
  inviteEmployeeTitle: "UserForm.inviteEmployeeTitle",
  emailLabel: "UserForm.emailLabel",
  insertEmailTitle: "UserForm.insertEmailTitle",
  passwordLabel: "UserForm.passwordLabel",
  confirmPasswordLabel: "UserForm.confirmPasswordLabel",
  resetPasswordLabel: "UserForm.resetPasswordLabel",
  resetPasswordTitle: "UserForm.resetPasswordTitle",
  resetPasswordSubtitle: "UserForm.resetPasswordSubtitle",
  requestResetPasswordButton: "UserForm.requestResetPasswordButton",
  salutationLabel: "UserForm.salutationLabel",
  firstNameLabel: "UserForm.firstNameLabel",
  lastNameLabel: "UserForm.lastNameLabel",
  phoneLabel: "UserForm.phoneLabel",
  faxLabel: "UserForm.faxLabel",
  languageLabel: "UserForm.languageLabel",
  isCEOLabel: "UserForm.isCEOLabel",
  inviteEmployeeButton: "UserForm.inviteEmployeeButton",
  requiredEmailError: "UserForm.requiredEmailError",
  minEmailError: "UserForm.minEmailError",
  maxEmailError: "UserForm.maxEmailError",
  invalidEmailError: "UserForm.invalidEmailError",
  requiredPasswordError: "UserForm.requiredPasswordError",
  minPasswordError: "UserForm.minPasswordError",
  maxPasswordError: "UserForm.maxPasswordError",
  invalidPasswordError: "UserForm.invalidPasswordError",
  passwordsMustMatchError: "UserForm.passwordsMustMatchError",
  requiredSalutationError: "UserForm.requiredSalutationError",
  invalidSalutationError: "UserForm.invalidSalutationError",
  requiredFirstNameError: "UserForm.requiredFirstNameError",
  minFirstNameError: "UserForm.minFirstNameError",
  maxFirstNameError: "UserForm.maxFirstNameError",
  requiredLastNameError: "UserForm.requiredLastNameError",
  minLastNameError: "UserForm.minLastNameError",
  maxLastNameError: "UserForm.maxLastNameError",
  requiredPhoneError: "UserForm.requiredPhoneError",
  minPhoneError: "UserForm.minPhoneError",
  maxPhoneError: "UserForm.maxPhoneError",
  invalidPhoneError: "UserForm.invalidPhoneError",
  minFaxError: "UserForm.minFaxError",
  maxFaxError: "UserForm.maxFaxError",
  invalidFaxError: "UserForm.invalidFaxError",
  requiredLanguageError: "UserForm.requiredLanguageError",
  requiredIsCEOError: "UserForm.requiredIsCEOError",
  mustBeCEOError: "UserForm.mustBeCEOError",
  saveUserSuccessMessage: "UserForm.saveUserSuccessMessage",
  emailCanNotBeChangedDescription: "UserForm.emailCanNotBeChangedDescription",
  requestPasswordResetSuccessMessage: "UserForm.requestPasswordResetSuccessMessage",
  requestPasswordResetSuccessTitle: "UserForm.requestPasswordResetSuccessTitle",
  requestPasswordResetSuccessSubtitle: "UserForm.requestPasswordResetSuccessSubtitle",
  requestPasswordResetFailedTitle: "UserForm.requestPasswordResetFailedTitle",
  requestPasswordResetFailedSubtitle: "UserForm.requestPasswordResetFailedSubtitle",
  passwordResetSuccessTitle: "UserForm.passwordResetSuccessTitle",
  passwordResetSuccessSubtitle: "UserForm.passwordResetSuccessSubtitle",
  passwordResetSuccessMessage: "UserForm.passwordResetSuccessMessage",
  passwordResetFailedTitle: "UserForm.passwordResetFailedTitle",
  passwordResetFailedSubtitle: "UserForm.passwordResetFailedSubtitle",
  profileLabel: "UserForm.profileLabel",
  apiTokenLabel: "UserForm.apiTokenLabel",
  apiTokenExpirationDate: "UserForm.apiTokenExpirationDate",
  generateNewApiTokenButton: "UserForm.generateNewApiTokenButton",
  companyIdLabel: "UserForm.companyIdLabel",
  generatedApiTokenDialogTitle: "UserForm.generatedApiTokenDialogTitle",
  generatedApiTokenDialogMessage: "UserForm.generatedApiTokenDialogMessage",
  generatedApiTokenSuccessMessage: "UserForm.generatedApiTokenSuccessMessage",
  generatedApiTokenFailedMessage: "UserForm.generatedApiTokenFailedMessage",
  newApiTokenDialogTitle: "UserForm.newApiTokenDialogTitle",
  lockFormDialogTitle: "UserForm.lockFormDialogTitle",
  lockFormDialogMessage: "UserForm.lockFormDialogMessage",
  noApiTokenDescription: "UserForm.noApiTokenDescription"
};

export const AuthenticationPageI18N = {
  loginButton: "AuthenticationPage.loginButton",
  continueButton: "AuthenticationPage.continueButton",
  finishButton: "AuthenticationPage.finishButton",
  loginMessage: "AuthenticationPage.loginMessage",
  description: "AuthenticationPage.description",
  termsAgreementLabel: "AuthenticationPage.termsAgreementLabel",
  scrollDownMessage: "AuthenticationPage.scrollDownMessage",
  privacyPolicyLabel: "AuthenticationPage.privacyPolicyLabel",
  confidentialityAgreementLabel: "AuthenticationPage.confidentialityAgreementLabel",
  termsAndConditionsLabel: "AuthenticationPage.termsAndConditionsLabel",
  signUpTitle: "AuthenticationPage.signUpTitle",
  agreementTermsTitle: "AuthenticationPage.agreementTermsTitle",
  accountDataTitle: "AuthenticationPage.accountDataTitle",
  personalDataTitle: "AuthenticationPage.personalDataTitle",
  generalCompanyTitle: "AuthenticationPage.generalCompanyTitle",
  addressDataTitle: "AuthenticationPage.addressDataTitle",
  requiredTermsAcceptedError: "AuthenticationPage.requiredTermsAcceptedError",
  requiredPrivacyPolicyAcceptedError: "AuthenticationPage.requiredPrivacyPolicyAcceptedError",
  requiredConfidentialityAcceptedError: "AuthenticationPage.requiredConfidentialityAcceptedError",
  requiredContractAndUsageAcceptedError: "AuthenticationPage.requiredContractAndUsageAcceptedError",
  registrationSuccessMessage: "AuthenticationPage.registrationSuccessMessage",
  registrationProcessUnderReview: "AuthenticationPage.registrationProcessUnderReview",
  goToHomeButton: "AuthenticationPage.goToHomeButton",
  backButton: "AuthenticationPage.backButton",
  registrationFailedMessage: "AuthenticationPage.registrationFailedMessage",
  registrationFailedDescription: "AuthenticationPage.registrationFailedDescription",
  loginFailedSummary: "AuthenticationPage.loginFailedSummary",
  loginFailedMessage: "AuthenticationPage.loginFailedMessage",
  agbLoadingError: "AuthenticationPage.agbLoadingError",
  privacyPolicyLoadingError: "AuthenticationPage.privacyPolicyLoadingError",
  confidentialityLoadingError: "AuthenticationPage.confidentialityLoadingError",
  termsAndConditionsLoadingError: "AuthenticationPage.termsAndConditionsLoadingError"
};

export const RequestStatusI18N = {
  DRAFT: "RequestStatus.DRAFT",
  CREATED: "RequestStatus.CREATED",
  PUBLISHED: "RequestStatus.PUBLISHED",
  TO_BE_CONFIRMED: "RequestStatus.TO_BE_CONFIRMED",
  CONFIRMED: "RequestStatus.CONFIRMED",
  DEACTIVATED: "RequestStatus.DEACTIVATED",
  CANCELLED: "RequestStatus.CANCELLED"
};

export const QuoteStatusI18N = {
  NOT_VACANT: "QuoteStatus.NOT_VACANT",
  VACANT: "QuoteStatus.VACANT",
  SUBMITTED: "QuoteStatus.SUBMITTED",
  ACCEPTED: "QuoteStatus.ACCEPTED",
  DISMISSED: "QuoteStatus.DISMISSED",
  REJECTED: "QuoteStatus.REJECTED"
};

export const PalletTypeI18N = {
  EURO_PALLET_NEW: "PalletType.EURO_PALLET_NEW",
  EURO_PALLET_NW: "PalletType.EURO_PALLET_NW",
  EURO_PALLET_USED: "PalletType.EURO_PALLET_USED",
  INDUSTRY_PALLET: "PalletType.INDUSTRY_PALLET",
  ONE_WAY_PALLET: "PalletType.ONE_WAY_PALLET"
};

export const PrintTypeI18N = {
  NO_PRINT: "PrintType.NO_PRINT",
  FULL_AREA_PRINT: "PrintType.FULL_AREA_PRINT",
  WARNING_PRINT: "PrintType.WARNING_PRINT"
};

export const NumberOfPalletI18N = {
  V_FLAT_PROFILE: "NumberOfPallet.V_FLAT_PROFILE",
  ONE_ON_THE_SIDE: "NumberOfPallet.ONE_ON_THE_SIDE",
  ONE_ON_THE_LONG_AND_SHORT_SIDE: "NumberOfPallet.ONE_ON_THE_LONG_AND_SHORT_SIDE"
};

export const RequestKeyWordsI18N = {
  externalRequests: "RequestKeyWords.externalRequests",
  internalRequests: "RequestKeyWords.internalRequests",
  quotationNumberError: "RequestKeyWords.quotationNumberError",
  contactPersonError: "RequestKeyWords.contactPersonError",
  maxRequestNumberLengthError: "RequestKeyWords.maxRequestNumberLengthError",
  maxQuoteNumberLengthError: "RequestKeyWords.maxQuoteNumberLengthError",
  deliveryDateFromError: "RequestKeyWords.deliveryDateFromError",
  deliveryDateToError: "RequestKeyWords.deliveryDateToError",
  deliveryDateRequiredError: "RequestKeyWords.deliveryDateRequiredError",
  dateFormatError: "RequestKeyWords.dateFormatError",
  varietyError: "RequestKeyWords.varietyError",
  maxVarietyError: "RequestKeyWords.maxVarietyError",
  fefcoCodeError: "RequestKeyWords.fefcoCodeError",
  maxFefcoCodeError: "RequestKeyWords.maxFefcoCodeError",
  palletTypeError: "RequestKeyWords.palletTypeError",
  requiredLengthError: "RequestKeyWords.requiredLengthError",
  minLengthError: "RequestKeyWords.minLengthError",
  maxLengthError: "RequestKeyWords.maxLengthError",
  requiredWidthError: "RequestKeyWords.requiredWidthError",
  minWidthError: "RequestKeyWords.minWidthError",
  maxWidthError: "RequestKeyWords.maxWidthError",
  requiredHeightError: "RequestKeyWords.requiredHeightError",
  minHeightError: "RequestKeyWords.minHeightError",
  maxHeightError: "RequestKeyWords.maxHeightError",
  requiredPrintError: "RequestKeyWords.requiredPrintError",
  requiredNumberOfColorsError: "RequestKeyWords.requiredNumberOfColorsError",
  minNumberOfColorsError: "RequestKeyWords.minNumberOfColorsError",
  maxNumberOfColorsError: "RequestKeyWords.maxNumberOfColorsError",
  palletLabelNeutralError: "RequestKeyWords.palletLabelNeutralError",
  stretchingError: "RequestKeyWords.stretchingError",
  closureTypeError: "RequestKeyWords.closureTypeError",
  requiredPalletHeightError: "RequestKeyWords.requiredPalletHeightError",
  minPalletHeightError: "RequestKeyWords.minPalletHeightError",
  maxPalletHeightError: "RequestKeyWords.maxPalletHeightError",
  requiredPalletOverhangError: "RequestKeyWords.requiredPalletOverhangError",
  minPalletOverhangError: "RequestKeyWords.minPalletOverhangError",
  maxPalletOverhangError: "RequestKeyWords.maxPalletOverhangError",
  numberOfPalletLabelError: "RequestKeyWords.numberOfPalletLabelError",
  requiredQuantityError: "RequestKeyWords.requiredQuantityError",
  minQuantityError: "RequestKeyWords.minQuantityError",
  maxQuantityError: "RequestKeyWords.maxQuantityError",
  integerError: "RequestKeyWords.integerError",
  requiredNetPriceError: "RequestKeyWords.requiredNetPriceError",
  minNetPriceError: "RequestKeyWords.minNetPriceError",
  maxNetPriceError: "RequestKeyWords.maxNetPriceError",
  addPriceButton: "RequestKeyWords.addPriceButton",
  previousQuantityInvalidOrEmptyMessage: "RequestKeyWords.previousQuantityInvalidOrEmptyMessage",
  requiredToolingCostsError: "RequestKeyWords.requiredToolingCostsError",
  minToolingCostsError: "RequestKeyWords.minToolingCostsError",
  maxToolingCostsError: "RequestKeyWords.maxToolingCostsError",
  requiredClicheCostsError: "RequestKeyWords.requiredClicheCostsError",
  minClicheCostsError: "RequestKeyWords.minClicheCostsError",
  maxClicheCostsError: "RequestKeyWords.maxClicheCostsError",
  titleCreateRequestPage: "RequestKeyWords.titleCreateRequestPage",
  titleCloneRequestPage: "RequestKeyWords.titleCloneRequestPage",
  titleYourRequestPage: "RequestKeyWords.titleYourRequestPage",
  colors: "RequestKeyWords.colors",
  customerRequestNumber: "RequestKeyWords.customerRequestNumber",
  customerQuoteNumber: "RequestKeyWords.customerQuoteNumber",
  quoteInformation: "RequestKeyWords.quoteInformation",
  deliveryDateRange: "RequestKeyWords.deliveryDateRange",
  deliveryDateLabel: "RequestKeyWords.deliveryDateLabel",
  deliveryAddress: "RequestKeyWords.deliveryAddress",
  companyAddress: "RequestKeyWords.companyAddress",
  variety: "RequestKeyWords.variety",
  fefcoCode: "RequestKeyWords.fefcoCode",
  palletType: "RequestKeyWords.palletType",
  internalDimensions: "RequestKeyWords.internalDimensions",
  externalPrintLabel: "RequestKeyWords.externalPrintLabel",
  internalPrintLabel: "RequestKeyWords.internalPrintLabel",
  numberOfColors: "RequestKeyWords.numberOfColors",
  numberOfPalletLabel: "RequestKeyWords.numberOfPalletLabel",
  palletLabelNeutral: "RequestKeyWords.palletLabelNeutral",
  pcsLabel: "RequestKeyWords.pcsLabel",
  pcsShortLabel: "RequestKeyWords.pcsShortLabel",
  stretching: "RequestKeyWords.stretching",
  closureType: "RequestKeyWords.closureType",
  palletizingFixedLabel: "RequestKeyWords.palletizingFixedLabel",
  palletizingNotesLabel: "RequestKeyWords.palletizingNotesLabel",
  maxPalletizingNotesLengthError: "RequestKeyWords.maxPalletizingNotesLengthError",
  Glue: "RequestKeyWords.Glue",
  Staple: "RequestKeyWords.Staple",
  Tap: "RequestKeyWords.Tap",
  siliconTapeLabel: "RequestKeyWords.siliconTapeLabel",
  tearTapeLabel: "RequestKeyWords.tearTapeLabel",
  counterPalletsLabel: "RequestKeyWords.counterPalletsLabel",
  palletExchangeLabel: "RequestKeyWords.palletExchangeLabel",
  allowPalletOverhang: "RequestKeyWords.allowPalletOverhang",
  palletOverhangLabel: "RequestKeyWords.palletOverhangLabel",
  quantityLabel: "RequestKeyWords.quantityLabel",
  totalPriceLabel: "RequestKeyWords.totalPriceLabel",
  totalPriceShortLabel: "RequestKeyWords.totalPriceShortLabel",
  palletHeight: "RequestKeyWords.palletHeight",
  netPriceLabel: "RequestKeyWords.netPriceLabel",
  netPriceShortLabel: "RequestKeyWords.netPriceShortLabel",
  separateExtraCosts: "RequestKeyWords.separateExtraCosts",
  toolingCostsLabel: "RequestKeyWords.toolingCostsLabel",
  clicheCostsLabel: "RequestKeyWords.clicheCostsLabel",
  companyDetailTitle: "RequestKeyWords.companyDetailTitle",
  companyName: "RequestKeyWords.companyName",
  contactPerson: "RequestKeyWords.contactPerson",
  phoneNumber: "RequestKeyWords.phoneNumber",
  telefax: "RequestKeyWords.telefax",
  paymentTerms: "RequestKeyWords.paymentTerms",
  additionalInformation: "RequestKeyWords.additionalInformation",
  cancelOrderButton: "RequestKeyWords.cancelOrderButton",
  status: "RequestKeyWords.status",
  statusTitle: "RequestKeyWords.statusTitle",
  viewDetailsTitle: "RequestKeyWords.viewDetailsTitle",
  publish: "RequestKeyWords.publish",
  delete: "RequestKeyWords.delete",
  edit: "RequestKeyWords.edit",
  copyButton: "RequestKeyWords.copyButton",
  confirmButton: "RequestKeyWords.confirmButton",
  sendQuoteButton: "RequestKeyWords.sendQuoteButton",
  successDelete: "RequestKeyWords.successDelete",
  failedDelete: "RequestKeyWords.failedDelete",
  confirmDelete: "RequestKeyWords.confirmDelete",
  deleteMessagePartOne: "RequestKeyWords.deleteMessagePartOne",
  deleteMessagePartTwo: "RequestKeyWords.deleteMessagePartTwo",
  deliveryDateFrom: "RequestKeyWords.deliveryDateFrom",
  deliveryDateTo: "RequestKeyWords.deliveryDateTo",
  length: "RequestKeyWords.length",
  height: "RequestKeyWords.height",
  width: "RequestKeyWords.width",
  unPublishSaveButton: "RequestKeyWords.unPublishSaveButton",
  modelMessage: "RequestKeyWords.modelMessage",
  deliveryAddressError: "RequestKeyWords.deliveryAddressError",
  netPriceFreeOfHouseFull: "RequestKeyWords.netPriceFreeOfHouseFull",
  clipboardCopyQuestion: "RequestKeyWords.clipboardCopyQuestion",
  stayButton: "RequestKeyWords.stayButton",
  discardButton: "RequestKeyWords.discardButton",
  discardTitle: "RequestKeyWords.discardTitle",
  discardMessage: "RequestKeyWords.discardMessage",
  successQuoteSubmittedMessage: "RequestKeyWords.successQuoteSubmittedMessage",
  questionSubmitQuoteMessage: "RequestKeyWords.questionSubmitQuoteMessage",
  confirmQuoteTitle: "RequestKeyWords.confirmQuoteTitle",
  requestDetailsTitle: "RequestKeyWords.requestDetailsTitle",
  externalRequestDetailsTitle: "RequestKeyWords.externalRequestDetailsTitle",
  noQuotesAvailable: "RequestKeyWords.noQuotesAvailable",
  quoteConfirmedMessage: "RequestKeyWords.quoteConfirmedMessage",
  quoteDismissedMessage: "RequestKeyWords.quoteDismissedMessage",
  cancelConfirmedNotification: "RequestKeyWords.cancelConfirmedNotification",
  selectQuoteError: "RequestKeyWords.selectQuoteError",
  submittedBy: "RequestKeyWords.submittedBy",
  submittedAt: "RequestKeyWords.submittedAt",
  ownRequestDetailsTitle: "RequestKeyWords.ownRequestDetailsTitle",
  receivedQuotesTitle: "RequestKeyWords.receivedQuotesTitle",
  confirmCancelRequestTitle: "RequestKeyWords.confirmCancelRequestTitle",
  confirmCancelRequestMessage: "RequestKeyWords.confirmCancelRequestMessage",
  withdrawQuoteButton: "RequestKeyWords.withdrawQuoteButton",
  questionQuoteWithdrawalMessage: "RequestKeyWords.questionQuoteWithdrawalMessage",
  confirmQuoteWithdrawalTitle: "RequestKeyWords.confirmQuoteWithdrawalTitle",
  successQuoteWithdrawalMessage: "RequestKeyWords.successQuoteWithdrawalMessage",
  withdrawRequestButton: "RequestKeyWords.withdrawRequestButton",
  confirmWithdrawRequestTitle: "RequestKeyWords.confirmWithdrawRequestTitle",
  confirmWithdrawRequestMessage: "RequestKeyWords.confirmWithdrawRequestMessage",
  withdrawConfirmedNotification: "RequestKeyWords.withdrawConfirmedNotification",
  backtoOverviewButton: "RequestKeyWords.backtoOverviewButton",
  emailHistoryButton: "RequestKeyWords.emailHistoryButton",
  palletHeightInfoTooltip: "RequestKeyWords.palletHeightInfoTooltip",
  numberOfPalletInfoTooltip: "RequestKeyWords.numberOfPalletInfoTooltip",
  palletNeutralInfoTooltip: "RequestKeyWords.palletNeutralInfoTooltip",
  netPriceInfoTooltip: "RequestKeyWords.netPriceInfoTooltip",
  copyRequestButton: "RequestKeyWords.copyRequestButton",
  questionConfirmQuoteMessage: "RequestKeyWords.questionConfirmQuoteMessage",
  deliveryDateOnOrAfterError: "RequestKeyWords.deliveryDateOnOrAfterError",
  deliveryDateOnOrBeforeError: "RequestKeyWords.deliveryDateOnOrBeforeError",
  quoteValidityDateOnOrAfterError: "RequestKeyWords.quoteValidityDateOnOrAfterError",
  quoteValidityDateOnOrBeforeError: "RequestKeyWords.quoteValidityDateOnOrBeforeError",
  quoteValidityRequiredError: "RequestKeyWords.quoteValidityRequiredError",
  requestValidityRequiredError: "RequestKeyWords.requestValidityRequiredError",
  unsavedChangesMessage: "RequestKeyWords.unsavedChangesMessage",
  maxCharactersLengthError: "RequestKeyWords.maxCharactersLengthError",
  confirmPublishWithoutNetPriceTitle: "RequestKeyWords.confirmPublishWithoutNetPriceTitle",
  confirmPublishWithoutNetPriceMessage: "RequestKeyWords.confirmPublishWithoutNetPriceMessage",
  uniqueQuantitiesError: "RequestKeyWords.uniqueQuantitiesError",
  quoteValidityLabel: "RequestKeyWords.quoteValidityLabel",
  requestValidityLabel: "RequestKeyWords.requestValidityLabel",
  deliveryDateFieldLabel: "RequestKeyWords.deliveryDateFieldLabel",
  requestedDeliveryDateLabel: "RequestKeyWords.requestedDeliveryDateLabel",
  proposedDeliveryDateLabel: "RequestKeyWords.proposedDeliveryDateLabel",
  requestedDeliveryDateRangeLabel: "RequestKeyWords.requestedDeliveryDateRangeLabel",
  quoteFormDialogSendQuoteTitle: "RequestKeyWords.quoteFormDialogSendQuoteTitle",
  quoteFormAcceptQuoteTitle: "RequestKeyWords.quoteFormAcceptQuoteTitle",
  quoteFormDialogDescription: "RequestKeyWords.quoteFormDialogDescription",
  pricingInquiryLabel: "RequestKeyWords.pricingInquiryLabel",
  acceptQuoteButton: "RequestKeyWords.acceptQuoteButton",
  setRequestedValueLabel: "RequestKeyWords.setRequestedValueLabel",
  quotesLabel: "RequestKeyWords.quotesLabel",
  ofLabel: "RequestKeyWords.ofLabel",
  externalDetailsPageTitle: "RequestKeyWords.externalDetailsPageTitle",
  tierLabel: "RequestKeyWords.tierLabel",
  printImagesLabel: "RequestKeyWords.printImagesLabel",
  constructionDrawingsLabel: "RequestKeyWords.constructionDrawingsLabel",
  requestSavedSuccessMessage: "RequestKeyWords.requestSavedSuccessMessage",
  quoteSubmittedSuccessMessage: "RequestKeyWords.quoteSubmittedSuccessMessage",
  dismissQuoteTitle: "RequestKeyWords.dismissQuoteTitle",
  questionDismissQuoteMessage: "RequestKeyWords.questionDismissQuoteMessage",
  rejectRequestButton: "RequestKeyWords.rejectRequestButton",
  revokeRequestButton: "RequestKeyWords.revokeRequestButton",
  requestRejectedSuccessMessage: "RequestKeyWords.requestRejectedSuccessMessage",
  questionRejectRequestMessage: "RequestKeyWords.questionRejectRequestMessage",
  requestRevokeSuccessMessage: "RequestKeyWords.requestRevokeSuccessMessage",
  questionRevokeRequestMessage: "RequestKeyWords.questionRevokeRequestMessage",
  quoteOverviewButton: "RequestKeyWords.quoteOverviewButton",
  infoLabel: "RequestKeyWords.infoLabel",
  uploadFilesError: "RequestKeyWords.uploadFilesError",
  hasPrintImagesLabel: "RequestKeyWords.hasPrintImagesLabel",
  hasBluePrintsLabel: "RequestKeyWords.hasBluePrintsLabel",
  hasUnansweredChatQuestionsLabel: "RequestKeyWords.hasUnansweredChatQuestionsLabel",
  hasAnsweredChatQuestionsLabel: "RequestKeyWords.hasAnsweredChatQuestionsLabel",
  hasPublishedChatQuestionsLabel: "RequestKeyWords.hasPublishedChatQuestionsLabel",
  hasAnyUnansweredChatQuestions: "RequestKeyWords.hasAnyUnansweredChatQuestions",
  hasOnlyAnsweredChatQuestions: "RequestKeyWords.hasOnlyAnsweredChatQuestions"
};

export const HeaderI18N = {
  createRequestButton: "Header.createRequestButton",
  logoutLabel: "Header.logoutLabel",
  logoutMessage: "Header.logoutMessage",
  signInButton: "Header.signInButton",
  externalRequestsButton: "Header.externalRequestsButton",
  internalRequestsButton: "Header.internalRequestsButton",
  companyButton: "Header.companyButton",
  requestButton: "Header.requestButton",
  myProfileButton: "Header.myProfileButton",
  myCompanyButton: "Header.myCompanyButton",
  adminConsoleButton: "Header.adminConsoleButton",
  changePasswordButton: "Header.changePasswordButton"
};

export const FooterI18N = {
  product: "Footer.product",
  features: "Footer.features",
  testimonials: "Footer.testimonials",
  highlights: "Footer.highlights",
  faqs: "Footer.faqs",
  company: "Footer.company",
  aboutUs: "Footer.aboutUs",
  careers: "Footer.careers",
  press: "Footer.press",
  legal: "Footer.legal",
  terms: "Footer.terms",
  privacy: "Footer.privacy",
  contact: "Footer.contact",
  privacyPolicy: "Footer.privacyPolicy",
  termsOfService: "Footer.termsOfService"
};

export const EmailHistoryI18N = {
  emailHistoryTitle: "EmailHistory.emailHistoryTitle",
  createdAt: "EmailHistory.createdAt",
  sentAt: "EmailHistory.sentAt",
  sendStatus: "EmailHistory.sendStatus",
  sender: "EmailHistory.sender",
  recipient: "EmailHistory.recipient",
  subject: "EmailHistory.subject",
  notSent: "EmailHistory.notSent",
  boxhubSystem: "EmailHistory.boxhubSystem",
  companies: "EmailHistory.companies",
  noRecipients: "EmailHistory.noRecipients",
  noEmailsAvailable: "EmailHistory.noEmailsAvailable",
  emailMessage: "EmailHistory.emailMessage"
};

export const EMailStatusI18N = {
  SENT: "EMailStatus.SENT",
  ERROR: "EMailStatus.ERROR",
  NOT_SENT: "EMailStatus.NOT_SENT"
};

export const GeneralI18N = {
  closeButton: "General.closeButton",
  cancelButton: "General.cancelButton",
  unknownError: "General.unknownError",
  pleaseSelect: "General.pleaseSelect",
  notGiven: "General.notGiven",
  yes: "General.yes",
  no: "General.no",
  refresh: "General.refresh"
};

export const LegalFormI18N = {
  AG: "LegalForm.AG",
  AG_KG: "LegalForm.AG_KG",
  AG_Co_KGaA: "LegalForm.AG_Co_KGaA",
  AG_Co_OHG: "LegalForm.AG_Co_OHG",
  gAG: "LegalForm.gAG",
  GbR: "LegalForm.GbR",
  GmbH: "LegalForm.GmbH",
  GmbH_Co_KG: "LegalForm.GmbH_Co_KG",
  GmbH_Co_KGaA: "LegalForm.GmbH_Co_KGaA",
  GmbH_Co_OHG: "LegalForm.GmbH_Co_OHG",
  GmbHG: "LegalForm.GmbHG",
  KG: "LegalForm.KG",
  KGaA: "LegalForm.KGaA",
  KGaA_Co_KG: "LegalForm.KGaA_Co_KG",
  OHG: "LegalForm.OHG",
  OHG_mbh: "LegalForm.OHG_mbh",
  PartG: "LegalForm.PartG",
  PartG_mbB: "LegalForm.PartG_mbB",
  Sonstige: "LegalForm.Sonstige",
  Stiftung_Co_KG: "LegalForm.Stiftung_Co_KG",
  UG_h: "LegalForm.UG_h",
  UG_hCo_KG: "LegalForm.UG_hCo_KG"
};

export const MessagesI18N = {
  sessionExpiredSummary: "Messages.sessionExpiredSummary",
  sessionExpiredDetails: "Messages.sessionExpiredDetails",
  accessDeniedSummary: "Messages.accessDeniedSummary",
  accessDeniedDetails: "Messages.accessDeniedDetails",
  loaded: "Messages.loaded",
  loadFailed: "Messages.loadFailed",
  saved: "Messages.saved",
  saveFailed: "Messages.saveFailed",
  validationError: "Messages.validationError",
  downloadFailed: "Messages.downloadFailed",
  downloadInProgress: "Messages.downloadInProgress",
  downloadInProgressDetails: "Messages.downloadInProgressDetails",
  contentTooLargeSummary: "Messages.contentTooLargeSummary",
  contentTooLargeDetails: "Messages.contentTooLargeDetails",
  uploadLimit: "Messages.uploadLimit"
};

export const ChatI18N = {
  ChatTitle: "Chat.ChatTitle",
  ChatEmpty: "Chat.ChatEmpty",
  Me: "Chat.Me",
  QuestionTitle: "Chat.QuestionTitle",
  QuestionHint: "Chat.QuestionHint",
  YourQuestionLabel: "Chat.YourQuestionLabel",
  QuestionLabel: "Chat.QuestionLabel",
  QuestionSendButton: "Chat.QuestionSendButton",
  AnswerButton: "Chat.AnswerButton",
  AnswerTitle: "Chat.AnswerTitle",
  AnswerLabel: "Chat.AnswerLabel",
  AnswerSendButton: "Chat.AnswerSendButton",
  ForEveryone: "Chat.ForEveryone",
  NotAnswered: "Chat.NotAnswered",
  chatQuestionError: "Chat.chatQuestionError",
  chatAnswerError: "Chat.chatAnswerError"
};

