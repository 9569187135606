import React, {useCallback} from "react";
import {AppBar, Grid2, Grid2 as Grid, PaletteMode, Tooltip, Typography, useTheme} from "@mui/material";
import {useAuth} from "@/app/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import {AppPaths} from "@/app/AppPaths";
import ToggleColorMode from "./ToggleColorMode";
import {APP_NAME} from "@/constants";
import {useDialogController} from "@/lib/dialog-controller";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {LogInIcon, LogOutIcon, User} from "lucide-react";
import {
    Add,
    AdminPanelSettings,
    Business,
    List,
    LockReset,
    PendingActions,
    UnfoldLess,
    UnfoldMore
} from "@mui/icons-material";
import {IconButtonEx} from "@/lib/components/buttons/IconButtonEx";
import {UserRole} from "@/api/data-contracts";
import {MenuButton, MenuButtonItem} from "@/lib/components/buttons/MenuButton";
import {ChangePasswordDialog} from "@/app/ChangePasswordDialog";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import {AdministrationI18N, GeneralI18N, HeaderI18N} from "@/translations";
import {useTranslation} from "react-i18next";
import {AdminDialog} from "@/app/AdminDIalog";

interface HeaderProps {
    mode: PaletteMode;
    toggleColorMode: () => void;
    expanded?: boolean;
    setExpanded?: (expanded: boolean) => void;
}

export const Header: React.FC<HeaderProps> = ({mode, toggleColorMode, expanded, setExpanded}) => {
    const {t} = useTranslation();
    const {authenticated, role, logout, user, admin, company, clientVersion, environment} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [DialogNode, showDialog] = useDialogController();

    const doLogout = useCallback(async () => {
        await logout();
        navigate(AppPaths.SIGN_IN);
    }, [logout, navigate]);

    const handleLogout = useCallback(() => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={doLogout}
                title={t(HeaderI18N.logoutLabel)}
                message={t(HeaderI18N.logoutMessage)}
                confirmButtonText={t(HeaderI18N.logoutLabel)}
                cancelButtonText={t(GeneralI18N.cancelButton)}
                maxWidth="sm"
                fullWidth
            />
        ));
    }, [doLogout, showDialog, t]);

    const handleChangePassword = useCallback(() => {
        showDialog((open, close) => <ChangePasswordDialog open={open} onClose={close}/>);
    }, [showDialog]);

    const handleAdminConsole = useCallback(() => {
        showDialog((open, close) => <AdminDialog open={open} onClose={close}/>);
    }, [showDialog]);

    const isCurrentPath = (path: string) => {
        const parts = path.split("?");
        if (parts.length > 1) {
            return location.pathname === parts[0] && location.search === `?${parts[1]}`;
        }
        return location.pathname === path;
    };

    const menu = useCallback(() => {
        const items: MenuButtonItem[] = [
            {
                label: t(HeaderI18N.myProfileButton),
                icon: <User/>,
                content: "link",
                to: user?.id ? AppPaths.USER_fn(user?.id) : undefined,
                useRouter: true
            },
            {
                label: t(HeaderI18N.myCompanyButton),
                icon: <Business/>,
                content: "link",
                to: company?.id ? AppPaths.COMPANY_fn(company?.id) : undefined,
                useRouter: true
            },
            {
                label: t(HeaderI18N.adminConsoleButton),
                icon: <AdminPanelSettings/>,
                content: handleAdminConsole,
                hidden: !admin || environment === "prod"
            },
            {
                label: t(HeaderI18N.changePasswordButton),
                icon: <LockReset/>,
                content: handleChangePassword
            },
            {
                content: "divider"
            },
            {
                label: t(HeaderI18N.logoutLabel),
                icon: <LogOutIcon/>,
                content: handleLogout,
                "data-cy": "logout-button"
            }
        ];
        return items;
    }, [admin, company?.id, handleAdminConsole, handleChangePassword, handleLogout, t, user?.id, environment]);

    const HeaderLink = ({
                            label,
                            path,
                            Icon,
                            "data-cy": dataCy
                        }: {
        label: string;
        path: string;
        Icon: React.ComponentType<any>;
        "data-cy": string;
    }) => {
        const selected = isCurrentPath(path);
        return (
            <ButtonEx
                label={label}
                color="primary"
                variant={selected ? "contained" : "outlined"}
                data-cy={dataCy}
                to={selected ? undefined : path}
                StartIcon={Icon}
            />
        );
    };

    const buttons =
        (role === UserRole.CLIENT && (
            <>
                <Grid2>
                    <HeaderLink
                        label={t(HeaderI18N.externalRequestsButton)}
                        path={AppPaths.EXTERNAL_REQUEST_OVERVIEW}
                        data-cy="dashboard-button"
                        Icon={List}
                    />
                </Grid2>
                <Grid2>
                    <HeaderLink
                        label={t(HeaderI18N.internalRequestsButton)}
                        path={AppPaths.INTERNAL_REQUEST_OVERVIEW}
                        data-cy="overview-button"
                        Icon={List}
                    />
                </Grid2>
                <Grid2>
                    <HeaderLink
                        label={t(HeaderI18N.createRequestButton)}
                        path={AppPaths.INTERNAL_REQUEST_fn("create")}
                        data-cy="create-request-button"
                        Icon={Add}
                    />
                </Grid2>
            </>
        )) ||
        (role === UserRole.OPERATOR && (
            <>
                <Grid2>
                    <HeaderLink
                        label={t(HeaderI18N.companyButton)}
                        path={AppPaths.COMPANY_OVERVIEW}
                        data-cy="company-overview-button"
                        Icon={Business}
                    />
                </Grid2>
                <Grid2>
                    <HeaderLink
                        label={t(AdministrationI18N.companyRequestsTitle)}
                        path={AppPaths.COMPANY_UNVERIFIED}
                        data-cy="company-unverified-button"
                        Icon={PendingActions}
                    />
                </Grid2>
            </>
        ));

    return (
        <AppBar
            position="relative"
            variant={theme.palette.mode === "light" ? "elevation" : "outlined"}
            sx={{
                // backgroundColor: theme.palette.background.default,
                backgroundColor: mode === "dark" ? "black" : "white",
                color: theme.palette.text.primary,
                // marginBottom: 1,
                borderBottomLeftRadius: expanded ? undefined : 4,
                borderBottomRightRadius: expanded ? undefined : 4,
                zIndex: theme.zIndex.drawer + 1,
                linearGradient: "none",
                paperShadow: "none"
                // height: 64,
            }}
        >
            <Grid2 container spacing={2} alignItems="center" paddingLeft={1} paddingRight={1}>
                <Grid
                    container
                    direction="row"
                    width="auto"
                    flexWrap="nowrap"
                    alignItems="center"
                    flexGrow={1}
                    spacing={2}
                >
                    <Grid container direction="row" width="auto" flexWrap="nowrap" spacing={1} paddingTop="4px">
                        <Grid>
                            <img
                                src="/boxhub-logo2-small.png"
                                color={mode === "dark" ? "#fff" : "#c5306e"}
                                alt={`${APP_NAME} Logo`}
                                width="42"
                                height="42"
                                style={mode === "dark" ? {filter: "invert(100%)"} : undefined}
                            />
                        </Grid>
                        <Grid>
                            <Grid container direction="column" paddingTop="3px">
                                <Grid>
                                    <Typography variant="body1" fontFamily="monospace">
                                        {APP_NAME}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant="body2" lineHeight="0.5rem">
                                        V{clientVersion}&nbsp;{environment && environment !== "prod" ? environment : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {buttons}
                </Grid>

                <Grid2 container spacing={0}>
                    <Grid2>
                        <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode}/>
                    </Grid2>

                    {setExpanded && (
                        <Grid2>
                            <IconButtonEx
                                Icon={expanded ? UnfoldLess : UnfoldMore}
                                rotate="90deg"
                                onClick={() => setExpanded(!expanded)}
                            />
                        </Grid2>
                    )}
                </Grid2>

                {authenticated ? (
                    <Grid2 container spacing={0}>
                        <Grid2 container direction="column" width="auto">
                            <Grid2>
                                <Tooltip title={`${user?.firstName || ""} ${user?.lastName || ""}`}>
                                    <Typography
                                        variant="body1"
                                        marginBottom="-4px"
                                        color={mode === "dark" ? "#fff" : "#c5306e"}
                                        sx={{
                                            maxWidth: "200px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {user?.firstName} {user?.lastName}
                                    </Typography>
                                </Tooltip>
                            </Grid2>
                            <Grid2>
                                <Tooltip title={company?.name || ""}>
                                    <Typography
                                        variant="body2"
                                        color={mode === "dark" ? "#fff" : "#c5306e"}
                                        sx={{
                                            maxWidth: "200px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}
                                    >
                                        {company?.name}
                                    </Typography>
                                </Tooltip>
                            </Grid2>
                        </Grid2>
                        <Grid2>
                            <MenuButton icon={<User fontSize="small"/>} menu={menu}/>
                        </Grid2>
                    </Grid2>
                ) : (
                    <Grid2>
                        <ButtonEx
                            label={t(HeaderI18N.signInButton)}
                            StartIcon={LogInIcon}
                            color="primary"
                            variant="contained"
                            to={AppPaths.SIGN_IN}
                            data-cy="signin-button"
                        />
                    </Grid2>
                )}
            </Grid2>
            <DialogNode/>
        </AppBar>
    );
};

