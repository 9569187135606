import {InputAdornment, MenuItem, TextField, TextFieldProps} from "@mui/material";
import React from "react";
import {FieldError} from "@/lib/utils";
import {useTranslation} from "react-i18next";
import {GeneralI18N} from "@/translations";

/**
 * Props for the SelectInputField component.
 *
 * @property {string} name - The name of the input field.
 * @property {string} label - The label for the input field.
 * @property {any} value - The current value of the input field.
 * @property {FieldError} [error] - The error object for the input field.
 * @property {{ label: string; value: any }[]} options - The options for the select input field.
 * @property {boolean} [displayEmpty] - If true, displays an empty option.
 * @property {boolean} [disabled] - If true, disables the input field.
 * @property {(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void} [onChange] - The handler for change events.
 * @property {React.ReactNode} [endAdornment] - The end adornment for the input field.
 * @property {boolean} [required] - If true, the input field is required.
 */
interface SelectInputFieldProps {
    name: string;
    label?: string;
    value: any;
    error?: FieldError;
    options: { label: string; value: any }[];
    displayEmpty?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    endAdornment?: React.ReactNode;
    required?: boolean;
    variant?: TextFieldProps["variant"];
    helperText?: string;
}

/**
 * SelectInputField component.
 *
 * @param {SelectInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const SelectInputField: React.FC<SelectInputFieldProps> = ({
                                                               name,
                                                               label,
                                                               value,
                                                               error,
                                                               options,
                                                               displayEmpty = false,
                                                               disabled = false,
                                                               onChange,
                                                               endAdornment,
                                                               required,
                                                               variant = "outlined",
                                                               helperText
                                                           }: SelectInputFieldProps): React.ReactElement => {
    const {t} = useTranslation();
    return (
        <TextField
            select
            name={name}
            label={label}
            value={value ?? ""}
            fullWidth
            size="small"
            disabled={disabled}
            error={!!error}
            helperText={error?.message ?? helperText}
            onChange={(e) => {
                if (!disabled && onChange) {
                    onChange(e);
                }
            }}
            required={required}
            variant={variant}
            slotProps={{
                input: {
                    endAdornment: endAdornment ? (
                        <InputAdornment position="end" sx={{position: "absolute", right: 24}}>
                            {endAdornment}
                        </InputAdornment>
                    ) : undefined
                },
                inputLabel: {shrink: true}
            }}
        >
            {!required && displayEmpty && (
                <MenuItem value="" disabled>
                    {t(GeneralI18N.pleaseSelect)}
                </MenuItem>
            )}
            {options.map((option) => (
                <MenuItem key={String(option.value)} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectInputField;
