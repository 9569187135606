import * as React from "react";
import {Grid2 as Grid, InputAdornment} from "@mui/material";
import {OrderDataDTO} from "@/api/data-contracts";
import {ChangeHandler} from "@/lib/modelstate";
import {CardEx} from "@/lib/components/CardEx";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import {numberOfPalletLabels, palletTypeOptions} from "@/datacaches";
import NumberInputField from "@/lib/components/inputfields/number-input-field";
import {CENTRIMETRE_UNIT} from "@/constants";
import InfoTooltipIcon from "@/lib/components/InfoTooltipIcon";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import {Constants} from "@/api/constants";
import {useTranslation} from "react-i18next";
import {NumberOfPalletI18N, PalletTypeI18N, RequestKeyWordsI18N} from "@/translations";
import {OrderDataType} from "@/model/orderData";
import {useCardChangeColors} from "@/common/request-cards/common";

export type PalletizingCardProps = {
    orderData?: OrderDataDTO;
    onChange: ChangeHandler;
    errors: any;
    mode: OrderDataType;
    readonly?: boolean;
    orgData?: OrderDataDTO | null;
};

export const PalletizingCard = ({orderData, orgData, errors, onChange, readonly, mode}: PalletizingCardProps) => {
    const {t} = useTranslation();
    const {getGridChangeSx} = useCardChangeColors();
    const palletTypeSelectOptions = palletTypeOptions.map((option) => ({
        label: option && t(PalletTypeI18N[option]),
        value: option
    }));
    const numberOfPalletLabelOptions = numberOfPalletLabels.map((option) => ({
        label: option && t(NumberOfPalletI18N[option]),
        value: option
    }));
    const readonlyEx = readonly || mode === "quote";

    const helperTextPalletizingNotes =
        orgData && orgData?.palletizingNotes !== orderData?.palletizingNotes ? orgData?.palletizingNotes : undefined;

    const helperTextPalletType =
        orgData && orgData?.palletType !== orderData?.palletType ? t(PalletTypeI18N[orgData?.palletType]) : undefined;

    const helperTextPalletHeight =
        orgData && orgData?.palletHeight !== orderData?.palletHeight ? orgData?.palletHeight : undefined;

    return (
        <CardEx title="Palettierung">
            <Grid container spacing={2} direction="column">
                <Grid size={{sm: 12}} container direction="row" spacing={2}>
                    <Grid size={{sm: 6}} sx={getGridChangeSx(orgData, helperTextPalletType)}>
                        <SelectInputField
                            name="orderData.palletType"
                            label={t(RequestKeyWordsI18N.palletType)}
                            onChange={onChange}
                            value={orderData?.palletType ?? ""}
                            error={errors?.orderData?.palletType}
                            options={palletTypeSelectOptions}
                            disabled={readonly}
                            helperText={helperTextPalletType}
                        />
                    </Grid>
                    <Grid size={{sm: 6}} sx={getGridChangeSx(orgData, helperTextPalletHeight)}>
                        <NumberInputField
                            name="orderData.palletHeight"
                            label={t(RequestKeyWordsI18N.palletHeight)}
                            onChange={onChange}
                            value={orderData?.palletHeight}
                            error={errors?.orderData?.palletHeight}
                            suffix={` ${CENTRIMETRE_UNIT}`}
                            endAdornment={
                                <InputAdornment position="end">
                                    <InfoTooltipIcon tooltipText={t(RequestKeyWordsI18N.palletHeightInfoTooltip)}/>
                                </InputAdornment>
                            }
                            min={Constants.PALLET_HEIGHT_MIN}
                            max={Constants.PALLET_HEIGHT_MAX}
                            helperText={helperTextPalletHeight}
                            disabled={readonly}
                        />
                    </Grid>
                </Grid>
                <Grid size={{sm: 12}} paddingLeft={1} container direction="row" spacing={1}>
                    <Grid>
                        <CheckboxInputField
                            name="orderData.counterPallets"
                            label={t(RequestKeyWordsI18N.counterPalletsLabel)}
                            onChange={onChange}
                            value={orderData?.counterPallets}
                            error={errors?.orderData?.counterPallets}
                            dense
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid>
                        <CheckboxInputField
                            name="orderData.palletExchange"
                            label={t(RequestKeyWordsI18N.palletExchangeLabel)}
                            onChange={onChange}
                            value={orderData?.palletExchange}
                            error={errors?.orderData?.palletExchange}
                            dense
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid>
                        <CheckboxInputField
                            name="orderData.palletLabelNeutral"
                            label={t(RequestKeyWordsI18N.palletLabelNeutral)}
                            onChange={onChange}
                            value={orderData?.palletLabelNeutral}
                            error={errors?.orderData?.palletLabelNeutral}
                            dense
                            disabled={readonlyEx}
                            // TODO: Wie InfoTooltipIcon einbinden?
                            // endAdornment={<InfoTooltipIcon tooltipText={t("palletNeutralInfoTooltip")} />}
                        />
                    </Grid>
                    <Grid>
                        <CheckboxInputField
                            name="orderData.stretching"
                            label={t(RequestKeyWordsI18N.stretching)}
                            onChange={onChange}
                            value={orderData?.stretching}
                            error={errors?.orderData?.stretching}
                            dense
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid size={{sm: 12}} container direction="row" spacing={2} flexWrap="nowrap">
                        <Grid flexGrow={1}>
                            <CheckboxInputField
                                name="orderData.allowPalletOverhang"
                                label={t(RequestKeyWordsI18N.allowPalletOverhang)}
                                onChange={onChange}
                                value={orderData?.allowPalletOverhang}
                                error={errors?.orderData?.allowPalletOverhang}
                                dense
                                disabled={readonlyEx}
                            />
                        </Grid>
                        <Grid>
                            <NumberInputField
                                name="orderData.palletOverhang"
                                label={t(RequestKeyWordsI18N.palletOverhangLabel)}
                                onChange={onChange}
                                value={orderData?.palletOverhang}
                                error={errors?.orderData?.palletOverhang}
                                suffix={` ${CENTRIMETRE_UNIT}`}
                                min={Constants.PALLET_OVERHANG_MIN}
                                max={Constants.PALLET_OVERHANG_MAX}
                                disabled={readonlyEx || !orderData?.allowPalletOverhang}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{sm: 12}}>
                    <SelectInputField
                        name="orderData.numberOfPalletLabel"
                        label={t(RequestKeyWordsI18N.numberOfPalletLabel)}
                        onChange={onChange}
                        value={orderData?.numberOfPalletLabel ?? ""}
                        error={errors?.orderData?.numberOfPalletLabel}
                        options={numberOfPalletLabelOptions}
                        endAdornment={
                            <InfoTooltipIcon tooltipText={t(RequestKeyWordsI18N.numberOfPalletInfoTooltip)}/>
                        }
                        disabled={readonlyEx}
                    />
                </Grid>
                <Grid size={{sm: 12}} paddingLeft="8px">
                    <CheckboxInputField
                        name="orderData.palletizingFixed"
                        label={t(RequestKeyWordsI18N.palletizingFixedLabel)}
                        onChange={onChange}
                        value={orderData?.palletizingFixed}
                        error={errors?.orderData?.palletizingFixed}
                        dense
                        disabled={readonlyEx}
                    />
                </Grid>
                {orderData?.palletizingFixed ? (
                    <Grid size={{sm: 12}} sx={getGridChangeSx(orgData, helperTextPalletizingNotes)}>
                        <StringInputField
                            name="orderData.palletizingNotes"
                            label={t(RequestKeyWordsI18N.palletizingNotesLabel)}
                            onChange={onChange}
                            value={orderData?.palletizingNotes}
                            error={errors?.orderData?.palletizingNotes}
                            helperText={helperTextPalletizingNotes}
                            multiline
                            maxChars={Constants.PALLETIZING_NOTES_LENGTH_MAX}
                            disabled={readonly || !orderData?.palletizingFixed}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </CardEx>
    );
};
