import {FormControl} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import React from "react";
import {NamedValueHandler} from "@/lib/modelstate";
import {FieldError} from "@/lib/utils";

/**
 * Props for the DateInputField component.
 *
 * @property {string} name - The name of the input field.
 * @property {string} label - The label for the input field.
 * @property {Date | string | null} [value] - The current value of the input field.
 * @property {NamedValueHandler} [onChange] - The handler for change events.
 * @property {FieldError} [error] - The error object for the input field.
 * @property {Dayjs} [minDate] - The minimum date allowed.
 * @property {Dayjs} [maxDate] - The maximum date allowed.
 * @property {string} [format] - The format for displaying the date.
 * @property {boolean} [disabled] - If true, disables the input field.
 * @property {"standard" | "outlined" | "filled"} [variant] - The variant of the input field.
 */
interface DateInputFieldProps {
    name: string;
    label: string;
    value?: Date | string | null;
    onChange?: NamedValueHandler;
    error?: FieldError;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    format?: string;
    disabled?: boolean;
    variant?: "standard" | "outlined" | "filled";
}

/**
 * DateInputField component.
 *
 * @param {DateInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const DateInputField: React.FC<DateInputFieldProps> = ({
                                                           name,
                                                           value,
                                                           label,
                                                           error,
                                                           minDate,
                                                           maxDate,
                                                           onChange,
                                                           format,
                                                           disabled,
                                                           variant
                                                       }: DateInputFieldProps): React.ReactElement => (
    <FormControl component="fieldset" error={!!error} fullWidth size="small" variant={variant}>
        <DatePicker
            name={name}
            label={label}
            value={value ? dayjs(value) : null}
            onChange={(newValue) => {
                // Set the time to noon (12:00 PM) to avoid issues with time zone differences
                // when converting the date to a JavaScript Date object.
                // This ensures that the date part is preserved correctly without shifting
                // to the previous or next day due to time zone offsets.
                // TODO: Implement a proper timezone solution to handle date conversions accurately.
                const dateValue = newValue ? newValue.hour(12).minute(0).second(0).millisecond(0).toDate() : null;
                if (onChange && !disabled) {
                    onChange({name, value: dateValue});
                }
            }}
            minDate={minDate}
            maxDate={maxDate}
            format={format}
            slotProps={{
                textField: {
                    error: !!error,
                    helperText: error?.message,
                    // helperText: error?.message && (
                    //     <div>
                    //         {error.message} {error.message}
                    //     </div>
                    // ),
                    size: "small",
                    InputLabelProps: {
                        shrink: true
                    }
                }
            }}
            disabled={disabled}
        />
    </FormControl>
);

export default DateInputField;
