import {Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText, SxProps, Theme} from "@mui/material";
import React from "react";
import {NamedValueHandler} from "@/lib/modelstate";
import {FieldError} from "@/lib/utils";
import {Check} from "lucide-react";
import {Close} from "@mui/icons-material";

/**
 * Props for the CheckboxInputField component.
 *
 * @property {string} name - The name of the checkbox input field.
 * @property {string} label - The label text for the checkbox.
 * @property {boolean} [value] - The checked state of the checkbox.
 * @property {NamedValueHandler} onChange - The function to call when the checkbox state changes.
 * @property {FieldError} [error] - The error message to display if there is an error.
 * @property {boolean} [disabled] - If true, the checkbox is disabled.
 * @property {CheckboxProps["size"]} [size] - The size of the checkbox.
 * @property {boolean} [dense] - If true, applies dense spacing to the checkbox.
 */
interface CheckboxInputFieldProps {
    name: string;
    label: string;
    value?: boolean;
    onChange?: NamedValueHandler;
    error?: FieldError;
    disabled?: boolean;
    size?: CheckboxProps["size"];
    dense?: boolean;
    sx?: SxProps<Theme>;
}

/**
 * CheckboxInputField component.
 *
 * @param {CheckboxInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered CheckboxInputField component.
 */
const CheckboxInputField: React.FC<CheckboxInputFieldProps> = ({
                                                                   name,
                                                                   label,
                                                                   value,
                                                                   onChange,
                                                                   error,
                                                                   disabled,
                                                                   size,
                                                                   dense,
                                                                   sx
                                                               }: CheckboxInputFieldProps): React.ReactElement => {
    return (
        <FormControl error={!!error} sx={sx}>
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={disabled}
                        name={name}
                        checked={value || false}
                        size={size}
                        onChange={(e) => {
                            if (!disabled && onChange) {
                                onChange({name, value: e.target.checked});
                            }
                        }}
                        sx={{
                            marginTop: dense ? "-4px " : undefined,
                            marginBottom: dense ? "-4px " : undefined
                        }}
                        checkedIcon={disabled ? <Check/> : undefined}
                        icon={disabled ? <Close/> : undefined}
                    />
                }
                label={label}
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

export default CheckboxInputField;
