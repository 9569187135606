import * as React from "react";
import { useState } from "react";
import { withAuthWrapperComponent } from "@/app/WithAuthWrapperComponent";
import { userApi } from "@/boxhub-api";
import { useParams } from "react-router-dom";
import { AppFrame } from "@/app/AppFrame";
import { useModelState } from "@/lib/modelstate";
import { CircularProgress, Grid2 as Grid, Typography } from "@mui/material";
import { UserDTO } from "@/api/data-contracts";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import { Lock, LockOpen } from "@mui/icons-material";
import { UserStatusBadge } from "@/common/UserStatusBadge";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { Constants } from "@/api/constants";
import InfoTooltipIcon from "@/lib/components/InfoTooltipIcon";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import { CardEx } from "@/lib/components/CardEx";
import { useUserEditSchema } from "@/pages/user-page/schema";
import { languageOptions, salutationOptions } from "@/datacaches";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { useTranslation } from "react-i18next";
import { AdministrationI18N, GeneralI18N, LanguagesI18N, SalutationsI18N, UserFormI18N } from "@/translations";
import { truncateString } from "@/lib/utils";
import { useAuth } from "@/app/AuthContext";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import { useSnackbarEx } from "@/lib/snackbarex";
import DateInputField from "@/lib/components/inputfields/date-input-field";
import { onClose, useDialogController } from "@/lib/dialog-controller";
import { UserTokenDialog } from "@/pages/user-page/UserTokenDialog";

const UserPage = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { user } = useAuth();
    const { showMessage } = useSnackbarEx();

    const salutationSelectOptions = salutationOptions.map((option) => ({
        label: option && t(SalutationsI18N[option]),
        value: option
    }));

    const languageSelectOptions = languageOptions.map((option) => ({
        label: option && t(LanguagesI18N[option]),
        value: option
    }));

    const [locked, setLocked] = useState(true);
    const [DialogNode, showDialog] = useDialogController();

    const schema = useUserEditSchema();

    const { model, errors, onChange, status, isDirty, dispatch, setErrors, save } = useModelState<UserDTO, number>({
        initialState: {},
        id: +params.id!,
        schema,
        loader: userApi.getUser,
        updater: userApi.saveUser,
        validateDisabled: locked
    });

    const iAmTheUser = user?.id === model?.id;

    const doSaveUser = () => {
        const promise = save();
        if (promise) {
            promise.then(() => {
                setLocked(true);
            });
        }
    };

    const pageTitle = (
        <>
            {`${t(UserFormI18N.profileLabel)} '${truncateString(model?.firstName, 32)} ${truncateString(model?.lastName, 32)}'`}{" "}
            <UserStatusBadge status={model?.status} />
        </>
    );

    const confirmLockForm = () => {
        if (isDirty) {
            dispatch({ type: "--reset" });
        }
        setLocked(true);
        setErrors({});
    };

    const showLockConfirmDialog = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={onClose(close, confirmLockForm)}
                title={t(UserFormI18N.lockFormDialogTitle)}
                message={t(UserFormI18N.lockFormDialogMessage)}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />
        ));
    };

    const handleLockToggle = () => {
        if (locked) {
            setLocked(false);
        } else {
            if (isDirty) {
                showLockConfirmDialog();
            } else {
                setLocked(true);
                setErrors({});
            }
        }
    };

    const handleNewToken = (expirationDate: string | undefined) => {
        dispatch({ type: "--change", data: ["apiTokenExpirationDate", expirationDate], onChange: true });
    };

    const confirmGenerateToken = () => {
        userApi
            .createApiToken()
            .then((response) => {
                showDialog((open, close) => (
                    <UserTokenDialog open={open} close={close} token={response.data} handleToken={handleNewToken} />
                ));
            })
            .catch((error) => {
                showMessage({ summary: t(UserFormI18N.generatedApiTokenFailedMessage), severity: "error", error });
            });
    };

    const showAskNewTokenDialog = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={onClose(close, confirmGenerateToken)}
                title={t(UserFormI18N.generatedApiTokenDialogTitle)}
                message={t(UserFormI18N.generatedApiTokenDialogMessage)}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />
        ));
    };

    const buttons = (
        <Grid container spacing={2}>
            {!locked && (
                <Grid>
                    <ButtonEx
                        label={
                            status === "saving" ? (
                                <CircularProgress size={24} />
                            ) : (
                                t(AdministrationI18N.saveChangesButton)
                            )
                        }
                        data-cy="save-user-button"
                        onClick={doSaveUser}
                        variant="contained"
                        color="primary"
                        disabled={!isDirty || status === "saving"}
                    />
                </Grid>
            )}
            {iAmTheUser && (
                <Grid>
                    <ButtonEx
                        label={t(UserFormI18N.generateNewApiTokenButton)}
                        data-cy={"generate-api-token-button"}
                        onClick={showAskNewTokenDialog}
                        variant="contained"
                        color="primary"
                    />
                </Grid>
            )}
            {iAmTheUser && (
                <Grid>
                    <IconButtonEx
                        Icon={locked ? Lock : LockOpen}
                        tooltip={
                            locked
                                ? t(AdministrationI18N.unlockForEditingDescription)
                                : t(AdministrationI18N.lockEditingDescription)
                        }
                        onClick={handleLockToggle}
                        disabled={status === "saving"}
                    />
                </Grid>
            )}
        </Grid>
    );

    return (
        <AppFrame modelState={status} title={pageTitle} buttons={buttons} isDirty={isDirty}>
            <Grid container spacing={2} padding={2}>
                <Grid size={{ xs: 12 }}>
                    <CardEx title={t(UserFormI18N.userFormTitle)}>
                        <Grid container spacing={3} direction="column" width="100%">
                            <Grid container direction="row" spacing={2} width="100%">
                                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                                    <StringInputField
                                        name="email"
                                        disabled
                                        value={model?.email}
                                        label={t(UserFormI18N.emailLabel)}
                                        error={errors?.email}
                                        maxChars={Constants.EMAIL_LENGTH_MAX}
                                        endAdornment={
                                            <InfoTooltipIcon
                                                tooltipText={t(UserFormI18N.emailCanNotBeChangedDescription)}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} width="100%">
                                <Grid size={{ sm: 4, md: 2, lg: 3 }}>
                                    <SelectInputField
                                        name="salutation"
                                        disabled={locked}
                                        label={t(UserFormI18N.salutationLabel)}
                                        value={model?.salutation}
                                        error={errors?.salutation}
                                        options={salutationSelectOptions}
                                        displayEmpty
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} width="100%">
                                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                                    <StringInputField
                                        name="firstName"
                                        disabled={locked}
                                        label={t(UserFormI18N.firstNameLabel)}
                                        value={model?.firstName}
                                        error={errors?.firstName}
                                        maxChars={Constants.FIRSTNAME_LENGTH_MAX}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                                    <StringInputField
                                        name="lastName"
                                        disabled={locked}
                                        label={t(UserFormI18N.lastNameLabel)}
                                        value={model?.lastName}
                                        error={errors?.lastName}
                                        maxChars={Constants.LASTNAME_LENGTH_MAX}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} width="100%">
                                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                                    <StringInputField
                                        name="telephone"
                                        disabled={locked}
                                        label={t(UserFormI18N.phoneLabel)}
                                        value={model?.telephone}
                                        error={errors?.telephone}
                                        maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                                    <StringInputField
                                        name="fax"
                                        disabled={locked}
                                        label={t(UserFormI18N.faxLabel)}
                                        value={model?.fax}
                                        error={errors?.fax}
                                        maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} width="100%">
                                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                                    <SelectInputField
                                        name="language"
                                        disabled={locked}
                                        label={t(UserFormI18N.languageLabel)}
                                        value={model?.language}
                                        error={errors?.language}
                                        options={languageSelectOptions}
                                        displayEmpty
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardEx>
                </Grid>
                {iAmTheUser && (
                    <Grid size={{ xs: 12 }}>
                        <CardEx title={t(UserFormI18N.apiTokenLabel)}>
                            <Grid container spacing={2} direction="column" width="100%">
                                <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                                    {model?.apiTokenExpirationDate ? (
                                        <DateInputField
                                            name="apiTokenExpirationDate"
                                            disabled
                                            value={model?.apiTokenExpirationDate}
                                            label={t(UserFormI18N.apiTokenExpirationDate)}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary" component="div">
                                            {t(UserFormI18N.noApiTokenDescription)}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </CardEx>
                    </Grid>
                )}
            </Grid>
            <DialogNode />
        </AppFrame>
    );
};

export default withAuthWrapperComponent(UserPage);
