// Auto-generated from Java REST API
/* eslint-disable no-useless-escape */

export const Constants = {
  ADDITIONAL_INFORMATION_LENGTH_MAX: 500,
  ADDITIONAL_INFORMATION_LENGTH_MIN: 1,
  BANKNAME_LENGTH_MAX: 100,
  BANKNAME_LENGTH_MIN: 3,
  BIC_LENGTH_MAX: 11,
  BIC_LENGTH_MIN: 8,
  BOXHUB_NUMBER_LENGTH_MAX: 50,
  BOXHUB_NUMBER_LENGTH_MIN: 1,
  CARTON_GRADE_LENGTH_MAX: 50,
  CHAT_MESSAGE_LENGTH_MAX: 200,
  CHAT_MESSAGE_LENGTH_MIN: 1,
  CITY_LENGTH_MAX: 100,
  CITY_LENGTH_MIN: 1,
  CLICHE_COSTS_PRICE_MAX: 1000000,
  CLICHE_COSTS_PRICE_MIN: 0,
  COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX: 20,
  COMMERCIAL_REGISTER_NUMBER_LENGTH_MIN: 4,
  COMPANY_NAME_LENGTH_MAX: 150,
  COMPANY_NAME_LENGTH_MIN: 2,
  CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX: 50,
  CUSTOMER_REFERENCE_NUMBER_LENGTH_MIN: 1,
  DELIVERY_INSTRUCTION_AND_ADVICE_NOTE_LENGTH_MAX: 100,
  DOCUMENTS_SIZE_MAX: 10,
  DOCUMENTS_TYPE_LENGTH_MAX: 5,
  EMAIL_LENGTH_MAX: 320,
  EMAIL_LENGTH_MIN: 4,
  ENV_DEVELOPMENT: "dev",
  ENV_PRODUCTION: "prod",
  ENV_STAGING: "staging",
  ERP_MANUFACTURER_LENGTH_MAX: 200,
  ERP_MANUFACTURER_LENGTH_MIN: 1,
  ERP_SOFTWARE_NAME_LENGTH_MAX: 200,
  ERP_SOFTWARE_NAME_LENGTH_MIN: 1,
  ERP_SOFTWARE_VERSION_LENGTH_MAX: 200,
  ERP_SOFTWARE_VERSION_LENGTH_MIN: 1,
  FEFCO_CODE_LENGTH_MAX: 50,
  FILENAME_LENGTH_MAX: 200,
  FILENAME_LENGTH_MIN: 2,
  FIRSTNAME_LENGTH_MAX: 50,
  FIRSTNAME_LENGTH_MIN: 2,
  I18N_VALUE_LENGTH_MAX: 1024,
  IBAN_LENGTH_MAX: 34,
  IBAN_LENGTH_MIN: 3,
  INTERNAL_DIMENSION_MAX: 5000,
  INTERNAL_DIMENSION_MIN: 1,
  LASTNAME_LENGTH_MAX: 100,
  LASTNAME_LENGTH_MIN: 2,
  NET_PRICE_PER_1000_MAX: 10000000,
  NET_PRICE_PER_1000_MIN: 0.01,
  NUMBER_OF_COLORS_MAX: 8,
  NUMBER_OF_COLORS_MIN: 0,
  OFFICE_HOURS_LENGTH_MAX: 250,
  OFFICE_HOURS_LENGTH_MIN: 1,
  PALLETIZING_NOTES_LENGTH_MAX: 1024,
  PALLET_HEIGHT_MAX: 240,
  PALLET_HEIGHT_MIN: 15,
  PALLET_OVERHANG_MAX: 10,
  PALLET_OVERHANG_MIN: 0,
  PASSWORD_LENGTH_MAX: 50,
  PASSWORD_LENGTH_MIN: 12,
  PAYMENT_TERMS_LENGTH_MAX: 512,
  PAYMENT_TERMS_LENGTH_MIN: 1,
  PHONE_NUMBER_LENGTH_MAX: 20,
  PHONE_NUMBER_LENGTH_MIN: 6,
  QUANTITY_MAX: 10000000,
  QUANTITY_MIN: 1,
  QUANTITY_OPTIONS_LENGTH_MAX: 5,
  QUANTITY_OPTIONS_LENGTH_MIN: 1,
  REASON_LENGTH_MAX: 500,
  REASON_LENGTH_MIN: 1,
  SETTINGS_VALUE_LENGTH_MAX: 32768,
  STREET_LENGTH_MAX: 150,
  STREET_LENGTH_MIN: 5,
  TOOLING_COSTS_PRICE_MAX: 1000000,
  TOOLING_COSTS_PRICE_MIN: 0,
  VAT_ID_LENGTH_MAX: 14,
  VAT_ID_LENGTH_MIN: 8,
  WEBSITE_LENGTH_MAX: 256,
  WEBSITE_LENGTH_MIN: 3,
  ZIP_LENGTH_MAX: 10,
  ZIP_LENGTH_MIN: 3,
} as const;
