import * as React from "react";
import { useCallback } from "react";
import { OrderDocumentDTO, OrderDocumentsDTO, OrderDocumentType } from "@/api/data-contracts";
import { ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import List from "@mui/material/List";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import { Delete, PictureAsPdf } from "@mui/icons-material";
import { FileUploadButtonEx } from "@/lib/components/buttons/FileUploadButton";
import { FileDropArea } from "@/lib/components/FileDropArea";
import { useFileUploadHandler } from "@/lib/file-upload-handler";
import { orderDataDocumentApi } from "@/boxhub-api";
import { GroupBox } from "@/lib/components/GroupBox";
import Typography from "@mui/material/Typography";
import { useSnackbarEx } from "@/lib/snackbarex";
import { MessagesI18N } from "@/translations";
import { useTranslation } from "react-i18next";
import { Constants } from "@/api/constants";

export type OrderDocumentsProps = {
    title: string;
    orderDocuments?: OrderDocumentsDTO;
    type: OrderDocumentType;
    onFileRemoved?: (file: OrderDocumentDTO) => void;
    onFileAdded?: (file: OrderDocumentDTO[]) => void;
    disabled?: boolean;
};

export const OrderDocuments = ({
    title,
    orderDocuments,
    type,
    onFileRemoved,
    onFileAdded,
    disabled
}: OrderDocumentsProps) => {
    const { t } = useTranslation();

    const { showMessage } = useSnackbarEx();

    const filtered = orderDocuments?.documents?.filter((file) => file.type === type);

    const readonly = disabled || orderDocuments?.id == null;

    const uploadFunction = useCallback(
        async (files: File[]) =>
            orderDataDocumentApi
                .uploadOrderDocument(
                    {
                        orderDocumentsId: orderDocuments?.id!,
                        type
                    },
                    {
                        files
                    }
                )
                .then((res) => {
                    if (onFileAdded) {
                        onFileAdded(res.data);
                    }
                    return res;
                })
                .catch((error) => {
                    if (error.status === 413) {
                        showMessage({
                            summary: t(MessagesI18N.uploadLimit, { maxSize: Constants.DOCUMENTS_SIZE_MAX }),
                            severity: "error"
                        });
                    } else {
                        showMessage({ summary: t(MessagesI18N.contentTooLargeSummary), severity: "error", error });
                    }
                }),
        [onFileAdded, orderDocuments?.id, type]
    );

    const uploader = useFileUploadHandler(uploadFunction);

    const handleDelete = (file: OrderDocumentDTO) => async () => {
        if (file.id) {
            try {
                await orderDataDocumentApi.deleteOrderDocument(file.id);
                if (onFileRemoved) {
                    onFileRemoved(file);
                }
            } catch (error) {
                showMessage({ summary: "Fehler beim Löschen der Datei", severity: "error", error });
            }
        }
    };

    const handleDownload = (file: OrderDocumentDTO) => {
        const url = `/api/order-documents/${file.id!}/download/${file.filenName}`;
        window.open(url, "_blank");
    };

    return (
        <GroupBox title={title} disabled={disabled}>
            <FileDropArea uploader={uploader} disabled={readonly}>
                <List dense>
                    {filtered?.map((file) => (
                        <ListItem
                            key={file.id}
                            sx={{ padding: 0, width: "100%", "&>div": { padding: 1 } }}
                            secondaryAction={
                                readonly ? undefined : (
                                    <IconButtonEx Icon={Delete} size="small" onClick={handleDelete(file)} />
                                )
                            }
                        >
                            <ListItemButton sx={{ padding: 2, width: "100%" }} onClick={() => handleDownload(file)}>
                                <ListItemIcon>
                                    <PictureAsPdf />
                                </ListItemIcon>
                                {file.filenName}
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {!readonly && filtered?.length! < 5 && (
                        <ListItem style={{ display: "flex", justifyContent: "center" }}>
                            <FileUploadButtonEx
                                uploader={uploader}
                                label="PDF-Datei hochladen"
                                size="small"
                                variant="text"
                                accept=".pdf"
                                fullWidth
                            />
                        </ListItem>
                    )}
                    {orderDocuments?.id == null && (
                        <ListItem style={{ display: "flex", justifyContent: "center" }}>
                            <Typography variant="subtitle1">Anfrage muss zuerst gespeichert werden</Typography>
                        </ListItem>
                    )}
                    {orderDocuments?.id != null && filtered?.length === 0 && (
                        <ListItem style={{ display: "flex", justifyContent: "center" }}>
                            <Typography variant="subtitle1">Kein Dokument vorhanden</Typography>
                        </ListItem>
                    )}
                </List>
            </FileDropArea>
        </GroupBox>
    );
};
