/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Reason of lock */
export interface AccessLockDTO {
    /** @format date-time */
    lockedAt?: string;
    lockedBy?: UserDisplayDTO;
    /**
     * @minLength 0
     * @maxLength 500
     */
    lockedReason?: string;
}

export interface AddressDTO {
    /**
     * @minLength 1
     * @maxLength 100
     */
    city: string;
    companyAddress?: boolean;
    country: Country;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    deliveryInstruction?: DeliveryInstructionDTO;
    /** @format int64 */
    id?: number;
    /**
     * @minLength 5
     * @maxLength 150
     */
    street?: string;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
    /**
     * @minLength 3
     * @maxLength 10
     * @pattern ^(?![A-Z]{3,})[A-Z0-9](?:[A-Z0-9\s-]*[A-Z0-9])?$
     */
    zip: string;
}

export interface AddressDisplayDTO {
    /**
     * @minLength 1
     * @maxLength 100
     */
    city: string;
    country: Country;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    /** @format int64 */
    id?: number;
    /**
     * @minLength 5
     * @maxLength 150
     */
    street?: string;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
    /**
     * @minLength 3
     * @maxLength 10
     * @pattern ^(?![A-Z]{3,})[A-Z0-9](?:[A-Z0-9\s-]*[A-Z0-9])?$
     */
    zip: string;
}

export interface ApiTokenDTO {
    apiToken?: string;
    companyId?: string;
    /** @format date */
    expirationDate?: string;
}

export interface CartonGradeDTO {
    code?: string;
    /** @format int64 */
    id?: number;
}

export interface ChangePasswordDTO {
    newPassword: string;
    newPasswordRepeat: string;
    oldPassword: string;
}

export interface ChatAnswerDTO {
    /** @format int64 */
    entryId?: number;
    forEveryone?: boolean;
    /** @format int64 */
    id?: number;
    message?: string;
}

export interface ChatEntryDTO {
    answer?: ChatMessageDTO;
    forEveryone?: boolean;
    from?: CompanyDisplayDTO;
    /** @format int64 */
    id?: number;
    question?: ChatMessageDTO;
    to?: CompanyDisplayDTO;
}

export interface ChatGroupDTO {
    entries?: ChatEntryDTO[];
    /** @format int64 */
    id?: number;
}

export interface ChatMessageDTO {
    author?: UserDisplayDTO;
    /** @format int64 */
    id?: number;
    message?: string;
    /** @format date-time */
    seenTime?: string;
    /** @format date-time */
    timestamp?: string;
}

export interface ChatQuestionDTO {
    /** @format int64 */
    chatGroupId?: number;
    /** @format int64 */
    id?: number;
    message?: string;
}

export enum CompanyActions {
    ADD_USER = "ADD_USER",
    CONFIRM_COMPANY_REGISTRATION = "CONFIRM_COMPANY_REGISTRATION",
    REJECT_COMPANY_REGISTRATION = "REJECT_COMPANY_REGISTRATION",
    LOCK_COMPANY = "LOCK_COMPANY",
    UNLOCK_COMPANY = "UNLOCK_COMPANY",
    TERMINATE_COMPANY = "TERMINATE_COMPANY",
    CANCEL_COMPANY_TERMINATION = "CANCEL_COMPANY_TERMINATION",
    REACTIVATE_COMPANY = "REACTIVATE_COMPANY"
}

export interface CompanyDTO {
    additionalInformation?: string;
    addresses?: AddressDTO[];
    /** @uniqueItems true */
    allowedActions?: CompanyActions[];
    bankName?: string;
    bic?: string;
    commercialRegistrationNumber?: string;
    companyAddress?: AddressDTO;
    contactPerson?: UserDisplayDTO;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    deliveryHours?: string;
    erpManufacturer?: string;
    erpSoftwareName?: string;
    erpSoftwareVersion?: string;
    iban?: string;
    /** @format int64 */
    id?: number;
    legalForm?: LegalForm;
    /** Reason of lock */
    lock?: AccessLockDTO;
    maschineryFleet?: MaschineryFleetDTO;
    name?: string;
    /** @format int32 */
    number?: number;
    officeHours?: string;
    paymentTerms?: string;
    status?: CompanyStatus;
    /** Reason of termination */
    termination?: CompanyTerminationDTO;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    users?: UserDisplayDTO[];
    vatIdentificationNumber?: string;
    /** @format int32 */
    version?: number;
    website?: string;
}

export interface CompanyDisplayDTO {
    additionalInformation?: string;
    companyAddress?: AddressDisplayDTO;
    /** @format int64 */
    id?: number;
    locked?: boolean;
    managingDirector?: string;
    name?: string;
    /** @format int32 */
    number?: number;
    paymentTerms?: string;
    status?: CompanyStatus;
}

export interface CompanyInvitationDTO {
    /**
     * @minLength 2
     * @maxLength 150
     */
    companyName: string;
    /**
     * @minLength 4
     * @maxLength 320
     * @pattern ^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$
     */
    email: string;
    /**
     * @minLength 2
     * @maxLength 50
     */
    firstName: string;
    invitationToken?: string;
    /**
     * @minLength 2
     * @maxLength 100
     */
    lastName: string;
    salutation: Salutation;
}

export interface CompanyOverviewRowDTO {
    /** @uniqueItems true */
    allowedActions?: CompanyActions[];
    company?: CompanyDisplayDTO;
    /** @format int32 */
    countActiveUsers?: number;
    /** @format int32 */
    countOfQuotes?: number;
    /** @format int32 */
    countOfRequests?: number;
    /** @format int64 */
    id?: number;
    /** @format date-time */
    lastUpdateOfCompanyStatus?: string;
}

export interface CompanySearchParamsDTO {
    companyName?: string;
    status?: CompanyStatus[];
}

export interface CompanySignUpDTO {
    /**
     * @minLength 1
     * @maxLength 500
     */
    additionalInformation: string;
    /**
     * @minLength 3
     * @maxLength 100
     */
    bankName: string;
    /**
     * @minLength 8
     * @maxLength 11
     * @pattern ^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$
     */
    bic: string;
    ceo?: boolean;
    /**
     * @minLength 1
     * @maxLength 100
     */
    city: string;
    /**
     * @minLength 4
     * @maxLength 20
     * @pattern ^(?:$|[A-Z0-9](?:[A-Z0-9\/\-\.\s]*[A-Z0-9])?)$
     */
    commercialRegisterNumber?: string;
    /**
     * @minLength 2
     * @maxLength 150
     */
    companyName: string;
    confidentialityAccepted: boolean;
    confirmPassword: string;
    contractAndUsageAccepted: boolean;
    country: Country;
    dieCutting: boolean;
    digitalPrinting: boolean;
    /**
     * @minLength 4
     * @maxLength 320
     * @pattern ^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$
     */
    email: string;
    /**
     * @minLength 1
     * @maxLength 200
     */
    erpManufacturer: string;
    /**
     * @minLength 1
     * @maxLength 200
     */
    erpSoftwareName: string;
    /**
     * @minLength 1
     * @maxLength 200
     */
    erpSoftwareVersion: string;
    /**
     * @minLength 6
     * @maxLength 20
     * @pattern ^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-\.\s]?\(?\d+\)?(?:[-\.\s]\(?\d+\)?)*$
     */
    fax?: string;
    /**
     * @minLength 2
     * @maxLength 50
     */
    firstName: string;
    flexoPrinting: boolean;
    folderGluerMachine: boolean;
    hazardousGoodsQualification: boolean;
    hdFlexoPrinting: boolean;
    /**
     * @minLength 3
     * @maxLength 34
     * @pattern ^[A-Z]{2}\d{2}[A-Z0-9]{11,30}$
     */
    iban: string;
    language: Language;
    /**
     * @minLength 2
     * @maxLength 100
     */
    lastName: string;
    legalForm: LegalForm;
    maxiliner: boolean;
    midliner: boolean;
    miniliner: boolean;
    /**
     * @minLength 1
     * @maxLength 250
     */
    officeHours: string;
    offsetPrinting: boolean;
    packaging: boolean;
    password: string;
    /**
     * @minLength 1
     * @maxLength 512
     */
    paymentTerms: string;
    /**
     * @minLength 6
     * @maxLength 20
     * @pattern ^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-\.\s]?\(?\d+\)?(?:[-\.\s]\(?\d+\)?)*$
     */
    phone: string;
    /**
     * @minLength 3
     * @maxLength 10
     * @pattern ^(?![A-Z]{3,})[A-Z0-9](?:[A-Z0-9\s-]*[A-Z0-9])?$
     */
    postalCode: string;
    privacyPolicyAccepted: boolean;
    salutation: Salutation;
    /**
     * @minLength 5
     * @maxLength 150
     */
    streetAddress?: string;
    termsAccepted: boolean;
    /**
     * @minLength 8
     * @maxLength 14
     * @pattern ^[A-Z]{2}(?!.*[A-Z]{7,})[A-Z0-9]+$
     */
    vatId: string;
    /**
     * @minLength 3
     * @maxLength 256
     * @pattern ^(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}(:\d{1,5})?(\/\S*)?$
     */
    website?: string;
}

export interface CompanyStatisticsDTO {
    /** @format int64 */
    companyId?: number;
    companyName?: string;
    /** @format int64 */
    numberOfAcceptedQuotes?: number;
    /** @format int64 */
    numberOfActiveUsers?: number;
    /** @format int64 */
    numberOfConfirmedRequests?: number;
    /** @format int64 */
    numberOfCreatedRequests?: number;
    /** @format int64 */
    numberOfPublishedRequests?: number;
    /** @format int64 */
    numberOfRejectedQuotes?: number;
    /** @format int64 */
    numberOfSubmittedQuotes?: number;
    /** @format int64 */
    numberOfToBeConfirmedRequests?: number;
    /** @format int64 */
    numberOfUsers?: number;
}

export enum CompanyStatus {
    OPERATOR = "OPERATOR",
    INVITED = "INVITED",
    UNVERIFIED = "UNVERIFIED",
    CONFIRMED = "CONFIRMED",
    CONFIRMED_LOCKED = "CONFIRMED_LOCKED",
    TERMINATION_RECEIVED = "TERMINATION_RECEIVED",
    TERMINATION_RECEIVED_LOCKED = "TERMINATION_RECEIVED_LOCKED",
    TERMINATED = "TERMINATED",
    REJECTED = "REJECTED",
    DEACTIVATED = "DEACTIVATED"
}

/** Reason of termination */
export interface CompanyTerminationDTO {
    /** @format date-time */
    requestAt?: string;
    requestBy?: UserDisplayDTO;
    /** @format date */
    requestExecDate: string;
    /**
     * @minLength 1
     * @maxLength 500
     */
    requestReason: string;
    requestSource: TerminationSource;
}

export enum Country {
    GERMANY = "GERMANY",
    NETHERLANDS = "NETHERLANDS"
}

export interface DeliveryInstructionDTO {
    address?: AddressDTO;
    /**
     * @minLength 0
     * @maxLength 100
     */
    arrivalNotification?: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    fridayInstruction?: string;
    /** @format int64 */
    id?: number;
    /**
     * @minLength 0
     * @maxLength 100
     */
    mondayInstruction?: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    saturdayInstruction?: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    sundayInstruction?: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    thursdayInstruction?: string;
    timeSlotBookingRequired?: boolean;
    /**
     * @minLength 0
     * @maxLength 100
     */
    tuesdayInstruction?: string;
    /**
     * @minLength 0
     * @maxLength 100
     */
    wednesdayInstruction?: string;
}

export interface DimensionDTO {
    /** @format int32 */
    height?: number;
    /** @format int32 */
    length?: number;
    /** @format int32 */
    width?: number;
}

export interface ErrorDTO {
    errors?: {
        empty?: boolean;
        [key: string]: any;
    };
    message?: string;
    /** @format int32 */
    status?: number;
}

export interface ErrorResponseDTO {
    error?: ErrorDTO;
}

export interface FefcoCodeDTO {
    code?: string;
    /** @format int64 */
    id?: number;
}

export enum Language {
    GERMAN = "GERMAN"
}

export enum LegalForm {
    AG = "AG",
    AG_KG = "AG_KG",
    AGCoKGaA = "AG_Co_KGaA",
    AGCoOHG = "AG_Co_OHG",
    GAG = "gAG",
    GbR = "GbR",
    GmbH = "GmbH",
    GmbHCoKG = "GmbH_Co_KG",
    GmbHCoKGaA = "GmbH_Co_KGaA",
    GmbHCoOHG = "GmbH_Co_OHG",
    GmbHG = "GmbHG",
    KG = "KG",
    KGaA = "KGaA",
    KGaACoKG = "KGaA_Co_KG",
    OHG = "OHG",
    OHGMbh = "OHG_mbh",
    PartG = "PartG",
    PartGMbB = "PartG_mbB",
    Sonstige = "Sonstige",
    StiftungCoKG = "Stiftung_Co_KG",
    UGH = "UG_h",
    UGHCoKG = "UG_hCo_KG"
}

export interface MailDTO {
    bcc?: string[];
    cc?: string[];
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    from?: string;
    /** @format int64 */
    id?: number;
    message?: string;
    /** @format date-time */
    sent?: string;
    status?: MailStatus;
    subject?: string;
    to?: string[];
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
}

export enum MailStatus {
    SENT = "SENT",
    NOT_SENT = "NOT_SENT",
    ERROR = "ERROR"
}

export interface MaschineryFleetDTO {
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    dieCutting?: boolean;
    digitalPrinting?: boolean;
    flexoPrinting?: boolean;
    folderGluerMachine?: boolean;
    hazardousGoodsQualification?: boolean;
    hdFlexoPrinting?: boolean;
    /** @format int64 */
    id?: number;
    maxiliner?: boolean;
    midliner?: boolean;
    miniliner?: boolean;
    offsetPrinting?: boolean;
    packaging?: boolean;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
}

export enum NumberOfPallet {
    ONE_ON_THE_SIDE = "ONE_ON_THE_SIDE",
    ONE_ON_THE_LONG_AND_SHORT_SIDE = "ONE_ON_THE_LONG_AND_SHORT_SIDE"
}

export interface OrderDataDTO {
    allowPalletOverhang?: boolean;
    /**
     * @minLength 0
     * @maxLength 50
     */
    cartonGrade?: string;
    clicheCosts?: number;
    counterPallets?: boolean;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    /** @format date */
    deliveryDate?: string;
    /** @format int32 */
    externalNumberOfColors?: number;
    externalPrint?: PrintType;
    /**
     * @minLength 0
     * @maxLength 50
     */
    fefcoCode?: string;
    glued?: boolean;
    /** @format int64 */
    id?: number;
    /**
     * @format int32
     * @min 1
     * @max 5000
     */
    internalDimensionHeight: number;
    /**
     * @format int32
     * @min 1
     * @max 5000
     */
    internalDimensionLength: number;
    /**
     * @format int32
     * @min 1
     * @max 5000
     */
    internalDimensionWidth: number;
    /** @format int32 */
    internalNumberOfColors?: number;
    internalPrint?: PrintType;
    numberOfPalletLabel?: NumberOfPallet;
    palletExchange?: boolean;
    /**
     * @format int32
     * @min 15
     * @max 240
     */
    palletHeight?: number;
    palletLabelNeutral?: boolean;
    /** @format int32 */
    palletOverhang?: number;
    palletType: PalletType;
    palletizingFixed?: boolean;
    /**
     * @minLength 0
     * @maxLength 1024
     */
    palletizingNotes?: string;
    /**
     * @minLength 0
     * @maxLength 512
     */
    paymentTerms?: string;
    /** @format int32 */
    quantityOptionSelected?: number;
    /**
     * @maxItems 5
     * @minItems 1
     */
    quantityOptions: QuantityOptionDTO[];
    separateExtraCosts?: boolean;
    siliconeTape: boolean;
    stitched?: boolean;
    stretching?: boolean;
    taped?: boolean;
    tearTape: boolean;
    toolingCosts?: number;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
}

export interface OrderDocumentDTO {
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    filenName?: string;
    /** @format int64 */
    id?: number;
    /** @format int64 */
    size?: number;
    type?: OrderDocumentType;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
}

export enum OrderDocumentType {
    BLUE_PRINT = "BLUE_PRINT",
    PRINT_IMAGE = "PRINT_IMAGE"
}

export interface OrderDocumentsDTO {
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    documents?: OrderDocumentDTO[];
    /** @format int64 */
    id?: number;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
}

export enum PalletType {
    EURO_PALLET_NEW = "EURO_PALLET_NEW",
    EURO_PALLET_NW = "EURO_PALLET_NW",
    EURO_PALLET_USED = "EURO_PALLET_USED",
    INDUSTRY_PALLET = "INDUSTRY_PALLET",
    ONE_WAY_PALLET = "ONE_WAY_PALLET"
}

export enum PrintType {
    NO_PRINT = "NO_PRINT",
    FULL_AREA_PRINT = "FULL_AREA_PRINT",
    WARNING_PRINT = "WARNING_PRINT"
}

export interface QuantityOptionDTO {
    /**
     * @min 0.01
     * @max 10000000
     */
    netPricePer1000?: number;
    netPriceTotal?: number;
    /**
     * @format int32
     * @min 1
     * @max 10000000
     */
    quantity: number;
}

export interface QuoteDTO {
    /**
     * @minLength 0
     * @maxLength 50
     */
    additionalInformation?: string;
    chatGroup?: ChatGroupDTO;
    company: CompanyDisplayDTO;
    contactPerson: UserDisplayDTO;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    /**
     * @minLength 1
     * @maxLength 50
     */
    customerReferenceNumber?: string;
    /** @format int64 */
    id?: number;
    orderData: OrderDataDTO;
    orderDocuments: OrderDocumentsDTO;
    requestDisplay: RequestDisplayDTO;
    status: QuoteStatus;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format date */
    validUntil?: string;
    /** @format int32 */
    version?: number;
}

export enum QuoteStatus {
    NOT_VACANT = "NOT_VACANT",
    VACANT = "VACANT",
    SUBMITTED = "SUBMITTED",
    ACCEPTED = "ACCEPTED",
    DISMISSED = "DISMISSED",
    REJECTED = "REJECTED"
}

export interface QuoteTerminationParamsDTO {
    /** @format date */
    requestExecDate?: string;
}

export interface QuoteValidationParamsDTO {
    /** @format date */
    deliveryDate?: string;
    /** @format date */
    validUntilQuote?: string;
    /** @format date */
    validUntilRequest?: string;
}

export enum RequestActions {
    UPDATE_REQUEST = "UPDATE_REQUEST",
    DELETE_REQUEST = "DELETE_REQUEST",
    PUBLISH_REQUEST = "PUBLISH_REQUEST",
    UNPUBLISH_REQUEST = "UNPUBLISH_REQUEST",
    CANCEL_REQUEST = "CANCEL_REQUEST",
    CANCEL_PREVIOUSLY_PUBLISHED_REQUEST = "CANCEL_PREVIOUSLY_PUBLISHED_REQUEST",
    CONFIRM_QUOTE = "CONFIRM_QUOTE",
    BID_FOR_REQUEST = "BID_FOR_REQUEST",
    WITHDRAW_QUOTE = "WITHDRAW_QUOTE",
    REJECT_REQUEST = "REJECT_REQUEST",
    REVOKE_REJECTED_QUOTE = "REVOKE_REJECTED_QUOTE",
    ANSWER_IN_CHAT = "ANSWER_IN_CHAT",
    ASK_IN_CHAT = "ASK_IN_CHAT"
}

export interface RequestDTO {
    /**
     * @minLength 1
     * @maxLength 500
     */
    additionalInformation?: string;
    /** @uniqueItems true */
    allowedActions?: RequestActions[];
    /**
     * @minLength 1
     * @maxLength 50
     */
    boxhubNumber?: string;
    chatGroup?: ChatGroupDTO;
    clonedBoxhubNumber?: string;
    company: CompanyDisplayDTO;
    contactPerson: UserDisplayDTO;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    /**
     * @minLength 1
     * @maxLength 50
     */
    customerReferenceNumber?: string;
    deliveryAddress: AddressDTO;
    /** @format date */
    deliveryDateFrom?: string;
    /** @format date */
    deliveryDateTo?: string;
    /** @format int64 */
    id?: number;
    orderData: OrderDataDTO;
    orderDocuments: OrderDocumentsDTO;
    quotes?: QuoteDTO[];
    status: RequestStatus;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format date */
    validUntil?: string;
    /** @format int32 */
    version?: number;
}

export interface RequestDetailsDTO {
    mails?: RequestMailDTO[];
}

export interface RequestDisplayDTO {
    additionalInformation?: string;
    boxhubNumber?: string;
    company?: CompanyDisplayDTO;
    /** @format date */
    confirmationDeadline?: string;
    contactPerson?: UserDisplayDTO;
    customerReferenceNumber?: string;
    deliveryAddress?: AddressDTO;
    /** @format date */
    deliveryDateFrom?: string;
    /** @format date */
    deliveryDateTo?: string;
    hasBluePrints?: boolean;
    hasPrintImages?: boolean;
    /** @format int64 */
    id?: number;
    orderData?: OrderDataDTO;
    quoteStatus?: QuoteStatus;
    /** @uniqueItems true */
    requestActions?: RequestActions[];
    requestStatus?: RequestStatus;
    /** @format date */
    submissionDeadline?: string;
    /** @format int32 */
    submittedQuoteCount?: number;
    /** @format date */
    validUntil?: string;
}

export enum RequestEventType {
    REQUEST_CREATED = "REQUEST_CREATED",
    REQUEST_PUBLISHED = "REQUEST_PUBLISHED",
    REQUEST_CONFIRMED = "REQUEST_CONFIRMED",
    REQUEST_CANCELLED = "REQUEST_CANCELLED",
    REQUEST_DISABLED = "REQUEST_DISABLED",
    REQUEST_DEACTIVATED = "REQUEST_DEACTIVATED",
    REQUEST_UNPUBLISHED = "REQUEST_UNPUBLISHED",
    REQUEST_ANSWER_QUESTION = "REQUEST_ANSWER_QUESTION",
    QUOTE_SUBMITTED = "QUOTE_SUBMITTED",
    QUOTE_DISMESSED = "QUOTE_DISMESSED",
    QUOTE_REJECTED = "QUOTE_REJECTED",
    QUOTE_ASK_QUESTION = "QUOTE_ASK_QUESTION",
    REQUEST_SUBMISSION_DEADLINE_REMINDER = "REQUEST_SUBMISSION_DEADLINE_REMINDER",
    REQUEST_SUBMISSION_DEADLINE_EXPIRED = "REQUEST_SUBMISSION_DEADLINE_EXPIRED",
    REQUEST_CONFIRMATION_DEADLINE_REMINDER = "REQUEST_CONFIRMATION_DEADLINE_REMINDER",
    REQUEST_CONFIRMATION_DEADLINE_EXPIRED = "REQUEST_CONFIRMATION_DEADLINE_EXPIRED",
    QUOTE_SUBMISSION_DEADLINE_REMINDER = "QUOTE_SUBMISSION_DEADLINE_REMINDER",
    QUOTE_SUBMISSION_DEADLINE_EXPIRED = "QUOTE_SUBMISSION_DEADLINE_EXPIRED",
    QUOTE_CONFIRMATION_DEADLINE_REMINDER = "QUOTE_CONFIRMATION_DEADLINE_REMINDER",
    QUOTE_CONFIRMATION_DEADLINE_EXPIRED = "QUOTE_CONFIRMATION_DEADLINE_EXPIRED"
}

export enum RequestFilter {
    EXTERNAL = "EXTERNAL",
    INTERNAL = "INTERNAL"
}

export interface RequestMailDTO {
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    event?: RequestEventType;
    /** @format int64 */
    id?: number;
    mail?: MailDTO;
    receiver?: CompanyDisplayDTO;
    /** @format int64 */
    requestEventId?: number;
    /** @format int64 */
    requestId?: number;
    sender?: CompanyDisplayDTO;
}

export interface RequestOverviewRowDTO {
    additionalInformation?: string;
    boxhubNumber?: string;
    cartonGrade?: string;
    company?: CompanyDisplayDTO;
    /** @format date */
    confirmationDeadline?: string;
    contactPerson?: UserDisplayDTO;
    customerReferenceNumber?: string;
    deliveryAddress?: AddressDisplayDTO;
    /** @format date */
    deliveryDateFrom?: string;
    /** @format date */
    deliveryDateTo?: string;
    /** @format int32 */
    externalNumberOfColors?: number;
    externalPrint?: PrintType;
    fefcoCode?: string;
    hasAnyUnansweredChatQuestions?: boolean;
    hasBluePrints?: boolean;
    hasMyChatQuestions?: boolean;
    hasMyUnansweredChatQuestions?: boolean;
    hasOnlyAnsweredChatQuestions?: boolean;
    hasPrintImages?: boolean;
    hasPublishedChatQuestions?: boolean;
    /** @format int64 */
    id?: number;
    internalDimension?: DimensionDTO;
    /** @format int32 */
    internalNumberOfColors?: number;
    internalPrint?: PrintType;
    quantityOptions?: QuantityOptionDTO[];
    quoteStatus?: QuoteStatus;
    /** @uniqueItems true */
    requestActions?: RequestActions[];
    requestStatus?: RequestStatus;
    /** @format date */
    submissionDeadline?: string;
    /** @format int32 */
    submittedQuoteCount?: number;
    /** @format date */
    validUntil?: string;
}

export interface RequestSearchParamsDTO {
    quoteStatuses?: QuoteStatus[];
    requestStatuses?: RequestStatus[];
    type?: RequestFilter;
}

export enum RequestStatus {
    DRAFT = "DRAFT",
    CREATED = "CREATED",
    PUBLISHED = "PUBLISHED",
    TO_BE_CONFIRMED = "TO_BE_CONFIRMED",
    CONFIRMED = "CONFIRMED",
    CANCELLED = "CANCELLED",
    DEACTIVATED = "DEACTIVATED"
}

export interface RequestValidationParamsDTO {
    /** @format date */
    deliveryDateFrom?: string;
    /** @format date */
    deliveryDateTo?: string;
    /** @format date */
    validUntil?: string;
}

export enum Salutation {
    MR = "MR",
    MS = "MS",
    DR_MALE = "DR_MALE",
    DR_FEMALE = "DR_FEMALE",
    PROF_MALE = "PROF_MALE",
    PROF_FEMALE = "PROF_FEMALE",
    DIVERS = "DIVERS"
}

export interface SearchResultCompanyOverviewRowDTO {
    limited?: boolean;
    rows?: CompanyOverviewRowDTO[];
}

export interface ServerInfoDTO {
    activeProfiles?: string[];
    baseUrl?: string;
    buildNumber?: string;
    environmentName?: string;
    environmentVariables?: Record<string, string>;
    hostName?: string;
    version?: string;
}

export interface SessionDTO {
    admin?: boolean;
    buildNumber?: string;
    company?: CompanyDisplayDTO;
    /** @format int32 */
    displacementDays?: number;
    environment?: string;
    /** @format int64 */
    id?: number;
    language?: Language;
    role?: UserRole;
    serverVersion?: string;
    user?: UserDisplayDTO;
}

export interface SignInDTO {
    /**
     * @minLength 4
     * @maxLength 320
     * @pattern ^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$
     */
    email: string;
    password: string;
}

export enum TerminationSource {
    OPERATOR = "OPERATOR",
    CLIENT = "CLIENT"
}

export interface TimeControlDTO {
    /** @format int32 */
    displacement?: number;
    /** @format date-time */
    now?: string;
    /** @format date */
    today?: string;
}

export interface UserDTO {
    /** Reason of lock */
    accessLock?: AccessLockDTO;
    /** @format date */
    apiTokenExpirationDate?: string;
    company?: CompanyDisplayDTO;
    /** @format date-time */
    createdAt?: string;
    createdBy?: UserDisplayDTO;
    email?: string;
    fax?: string;
    firstName?: string;
    /** @format int64 */
    id?: number;
    language?: Language;
    lastName?: string;
    salutation?: Salutation;
    status?: UserStatus;
    telephone?: string;
    /** @format date-time */
    updatedAt?: string;
    updatedBy?: UserDisplayDTO;
    /** @format int32 */
    version?: number;
}

export interface UserDisplayDTO {
    ceo?: boolean;
    email?: string;
    fax?: string;
    firstName?: string;
    /** @format int64 */
    id?: number;
    lastName?: string;
    locked?: boolean;
    salutation?: Salutation;
    status?: UserStatus;
    telephone?: string;
}

export interface UserInvitationDTO {
    /**
     * @minLength 4
     * @maxLength 320
     * @pattern ^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$
     */
    email: string;
    /**
     * @minLength 6
     * @maxLength 20
     * @pattern ^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-\.\s]?\(?\d+\)?(?:[-\.\s]\(?\d+\)?)*$
     */
    fax?: string;
    /**
     * @minLength 2
     * @maxLength 50
     */
    firstName: string;
    language: Language;
    /**
     * @minLength 2
     * @maxLength 100
     */
    lastName: string;
    /**
     * @minLength 6
     * @maxLength 20
     * @pattern ^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-\.\s]?\(?\d+\)?(?:[-\.\s]\(?\d+\)?)*$
     */
    phone: string;
    salutation: Salutation;
}

export interface UserPasswordResetDTO {
    confirmPassword: string;
    /**
     * @minLength 4
     * @maxLength 320
     * @pattern ^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$
     */
    email: string;
    password: string;
}

export enum UserRole {
    OPERATOR = "OPERATOR",
    CLIENT = "CLIENT",
    ADMIN = "ADMIN"
}

export interface UserSignUpDTO {
    company?: CompanyDisplayDTO;
    confirmPassword: string;
    /**
     * @minLength 4
     * @maxLength 320
     * @pattern ^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$
     */
    email: string;
    /**
     * @minLength 6
     * @maxLength 20
     * @pattern ^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-\.\s]?\(?\d+\)?(?:[-\.\s]\(?\d+\)?)*$
     */
    fax?: string;
    /**
     * @minLength 2
     * @maxLength 50
     */
    firstName: string;
    language: Language;
    /**
     * @minLength 2
     * @maxLength 100
     */
    lastName: string;
    password: string;
    /**
     * @minLength 6
     * @maxLength 20
     * @pattern ^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-\.\s]?\(?\d+\)?(?:[-\.\s]\(?\d+\)?)*$
     */
    phone: string;
    salutation: Salutation;
}

export enum UserStatus {
    INVITED = "INVITED",
    UNCONFIRMED = "UNCONFIRMED",
    EMAIL_CONFIRMED = "EMAIL_CONFIRMED",
    CONFIRMED = "CONFIRMED",
    DEACTIVATED = "DEACTIVATED"
}
