import {Autocomplete, FormControl, TextField} from "@mui/material";
import React from "react";
import {NamedValueHandler} from "@/lib/modelstate";
import {autoCompleteOff, FieldError} from "@/lib/utils";
import isEqual from "react-fast-compare";

/**
 * Props for the AutocompleteInputField component.
 *
 * @template T - The type of the options.
 * @property {string} name - The name of the input field.
 * @property {T | null} [value] - The current value of the input field.
 * @property {string} label - The label for the input field.
 * @property {NamedValueHandler} onChange - The handler for change events.
 * @property {FieldError} [error] - The error object for the input field.
 * @property {T[]} options - The list of options for the autocomplete.
 * @property {(option: T) => string} [getOptionLabel] - Function to get the label of an option.
 * @property {(option: T, value: T) => boolean} [isOptionEqualToValue] - Function to compare an option with the current value.
 * @property {boolean} [freeSolo] - If true, allows free solo input.
 * @property {number} [maxChars] - The maximum number of characters allowed for free solo input.
 * @property {RegExp} [matchRule] - The regex rule to match the free solo input.
 * @property {boolean} [disabled] - If true, disables the input field.
 */
interface AutocompleteInputFieldProps<T> {
    name: string;
    value?: T | null;
    label: string;
    onChange?: NamedValueHandler;
    error?: FieldError;
    options: T[];
    getOptionLabel?: (option: T) => string;
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    freeSolo?: boolean;
    maxChars?: number;
    disabled?: boolean;
    helperText?: string;
    backgroundColor?: string;
}

/**
 * AutocompleteInputField component.
 *
 * @template T - The type of the options.
 * @param {AutocompleteInputFieldProps<T>} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const AutocompleteInputField = <T = any, >({
                                               name,
                                               label,
                                               value,
                                               onChange,
                                               error,
                                               options,
                                               getOptionLabel,
                                               isOptionEqualToValue,
                                               freeSolo,
                                               maxChars,
                                               helperText,
                                               backgroundColor,
                                               disabled = false
                                           }: AutocompleteInputFieldProps<T>): React.ReactElement => (
    <FormControl fullWidth error={!!error}>
        <Autocomplete
            data-cy={name}
            options={options}
            disabled={disabled}
            size="small"
            style={{backgroundColor}}
            // @ts-ignore
            getOptionLabel={getOptionLabel}
            onChange={(_event, newValue) => {
                if (!isEqual(value, newValue) && onChange && !disabled) {
                    onChange({name, value: newValue});
                }
            }}
            onInputChange={(event, newInputValue) => {
                if (freeSolo && onChange && !disabled) {
                    onChange({
                        name,
                        value: newInputValue || ""
                    });
                }
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={!!error}
                    helperText={error?.message ?? helperText}
                    autoComplete={autoCompleteOff}
                    size="small"
                    slotProps={{
                        inputLabel: {shrink: true},
                        htmlInput: {...params.inputProps, maxLength: maxChars}
                    }}
                />
            )}
            value={value ?? ""}
            freeSolo={freeSolo}
        />
    </FormControl>
);
export default AutocompleteInputField;
