import { Grid2 } from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { useModelState } from "@/lib/modelstate";
import { authenticationApi } from "@/boxhub-api";
import { AppPaths } from "@/app/AppPaths";
import Typography from "@mui/material/Typography";
import { CompanyInvitationDTO } from "@/api/data-contracts";
import * as React from "react";
import { useMemo } from "react";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import { Clipboard } from "lucide-react";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import { salutationOptions } from "@/datacaches";
import * as yup from "yup";
import { EMAIL_REGEX } from "@/regex";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { CompanyFormI18N, GeneralI18N, SalutationsI18N, UserFormI18N } from "@/translations";
import { DialogEx } from "@/lib/components/DialogEx";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";

export type CompanyInvitationDialogProps = {
    open: boolean;
    close: () => void;
};

const useSchema = () => {
    const { t } = useTranslation();
    return useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .required(t(UserFormI18N.requiredEmailError))
                    .min(
                        Constants.EMAIL_LENGTH_MIN,
                        t(UserFormI18N.minEmailError, { minInteger: Constants.EMAIL_LENGTH_MIN })
                    )
                    .max(
                        Constants.EMAIL_LENGTH_MAX,
                        t(UserFormI18N.maxEmailError, { maxInteger: Constants.EMAIL_LENGTH_MAX })
                    )
                    .matches(EMAIL_REGEX, t(UserFormI18N.invalidEmailError)),

                salutation: yup
                    .string()
                    .required(t(UserFormI18N.requiredSalutationError))
                    .oneOf(salutationOptions, t(UserFormI18N.invalidSalutationError)),

                firstName: yup
                    .string()
                    .required(t(UserFormI18N.requiredFirstNameError))
                    .min(
                        Constants.FIRSTNAME_LENGTH_MIN,
                        t(UserFormI18N.minFirstNameError, { minInteger: Constants.FIRSTNAME_LENGTH_MIN })
                    )
                    .max(
                        Constants.FIRSTNAME_LENGTH_MAX,
                        t(UserFormI18N.maxFirstNameError, { maxInteger: Constants.FIRSTNAME_LENGTH_MAX })
                    ),

                lastName: yup
                    .string()
                    .required(t(UserFormI18N.requiredLastNameError))
                    .min(
                        Constants.LASTNAME_LENGTH_MIN,
                        t(UserFormI18N.minLastNameError, { minInteger: Constants.LASTNAME_LENGTH_MIN })
                    )
                    .max(
                        Constants.LASTNAME_LENGTH_MAX,
                        t(UserFormI18N.maxLastNameError, { maxInteger: Constants.LASTNAME_LENGTH_MAX })
                    ),

                companyName: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCompanyNameError))
                    .min(
                        Constants.COMPANY_NAME_LENGTH_MIN,
                        t(CompanyFormI18N.minCompanyNameError, { minInteger: Constants.COMPANY_NAME_LENGTH_MIN })
                    )
                    .max(
                        Constants.COMPANY_NAME_LENGTH_MAX,
                        t(CompanyFormI18N.maxCompanyNameError, { maxInteger: Constants.COMPANY_NAME_LENGTH_MAX })
                    )
            }) as yup.ObjectSchema<CompanyInvitationDTO>,
        [t]
    );
};

export const CompanyInvitationDialog = ({ open, close }: CompanyInvitationDialogProps) => {
    const { t } = useTranslation();
    const salutationSelectOptions = salutationOptions.map((option) => ({
        label: option && t(SalutationsI18N[option]),
        value: option
    }));
    const schema = useSchema();
    const { model, errors, onChange, save } = useModelState<CompanyInvitationDTO>({
        initialState: {} as CompanyInvitationDTO,
        schema,
        saver: authenticationApi.generateCompanySignUpInvitation
    });

    const edit = model.invitationToken == null;

    const buttons = (
        <>
            <Grid2>
                <ButtonEx
                    label={edit ? t(GeneralI18N.cancelButton) : t(GeneralI18N.closeButton)}
                    color="secondary"
                    variant="contained"
                    onClick={close}
                />
            </Grid2>
            {edit && (
                <Grid2>
                    <ButtonEx
                        label="Einladen"
                        color="primary"
                        variant="contained"
                        disabled={model.email == null}
                        onClick={() => save()}
                    />
                </Grid2>
            )}
        </>
    );

    return (
        <DialogEx
            open={open}
            onClose={close}
            maxWidth="sm"
            fullWidth
            title={t(CompanyFormI18N.inviteCompanyTitle)}
            buttons={buttons}
        >
            <Grid2 container spacing={2} padding={2} width="100%">
                {edit ? (
                    <>
                        <Grid2 width="100%">
                            <StringInputField
                                name="companyName"
                                value={model?.companyName}
                                label={t(CompanyFormI18N.companyNameLabel)}
                                error={errors?.companyName}
                                maxChars={Constants.COMPANY_NAME_LENGTH_MAX}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 width="100%">
                            <SelectInputField
                                name="salutation"
                                label={t(UserFormI18N.salutationLabel)}
                                value={model?.salutation}
                                error={errors?.salutation}
                                options={salutationSelectOptions}
                                displayEmpty={true}
                                onChange={onChange}
                            />
                        </Grid2>

                        <Grid2 width="100%">
                            <StringInputField
                                name="firstName"
                                label={t(UserFormI18N.firstNameLabel)}
                                value={model?.firstName}
                                error={errors?.firstName}
                                maxChars={Constants.FIRSTNAME_LENGTH_MAX}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 width="100%">
                            <StringInputField
                                name="lastName"
                                label={t(UserFormI18N.lastNameLabel)}
                                value={model?.lastName}
                                error={errors?.lastName}
                                maxChars={Constants.LASTNAME_LENGTH_MAX}
                                onChange={onChange}
                            />
                        </Grid2>
                        <Grid2 width="100%">
                            <StringInputField
                                name="email"
                                value={model?.email}
                                label={t(UserFormI18N.emailLabel)}
                                error={errors?.email}
                                maxChars={Constants.EMAIL_LENGTH_MAX}
                                onChange={onChange}
                            />
                        </Grid2>
                    </>
                ) : (
                    <Grid2 container direction="row" spacing={2}>
                        <Grid2>
                            <Typography variant="h6">Die Einladung wurde versandt.</Typography>
                        </Grid2>
                        <Grid2>
                            <IconButtonEx
                                Icon={Clipboard}
                                size="small"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        `${window.location.origin}#${AppPaths.COMPANY_SIGNUP_fn(model.invitationToken!)}`
                                    )
                                }
                                title="Link kopieren"
                            />
                        </Grid2>
                    </Grid2>
                )}
            </Grid2>
        </DialogEx>
    );
};
